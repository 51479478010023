<template>
    <div style="margin: 0 15px;display: flex;flex-direction: column">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="关于我们" name="1">
          <div style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a" @click="toPath('/',0)">公司介绍</div>
          <div style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">加入我们</div>
          <div style="margin-bottom: 10px;padding: 15px 0 1px 20px;" @click="toPath('/connectionWe',6)">联系我们</div>
        </el-collapse-item>
        <el-collapse-item title="主营业务" name="2">
          <div style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">产品中心</div>
          <div style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">解决方案</div>
        </el-collapse-item>
        <el-collapse-item title="案例中心" name="3">
          <!-- @click="toPath('/SecureCenter',2,index+1) -->
          <div v-for="(item, index) in caseList" :key="index"
               style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a;cursor: pointer" @click="goSecure(item)">{{item.name}}</div>
        </el-collapse-item>
<!--        <el-collapse-item title="其他" name="4">
          <div style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">法律声明</div>
        </el-collapse-item>-->
      </el-collapse>
      <div style="margin-top: 15px;display: flex;">
        <div style="width: 80px;height: 80px;background: white">
          <img style="width: 100%;height: 100%;" src="@/assets/imgs/erweima.png" alt="">
        </div>
        <div style="display: flex;flex-direction: column;justify-content: space-between;font-size: 14px;opacity: 0.6;margin-left: 16px;">
          <div style="display: flex;flex-direction: column;display: flex;flex-direction: column;">
            <span>客服服务热线</span>
            <span>028-801-92812</span>
          </div>
          <div style="font-size: 12px">- 关注我们 -</div>
        </div>
      </div>
    </div>

</template>

<script>
export default {
  props:['caseList'],
  data() {
    return {
      activeNames: ['0']
    };
  },
  methods: {
    goSecure(item){
      if(this.$route.path != '/SecureCenter'){
        this.$router.push({
          path:'/SecureCenter'
        })
      }
      setTimeout(() => {
        this.$bus.$emit('goSecure',item)
      }, 200);
    },
    toPath(url,index,tabsIndex=null){
      this.$store.commit('setTabsIndex', index);
      this.$router.push({
        path:url,
        query: { index: tabsIndex }
      }).catch(err => {
        console.log('输出报错',err)
      })
    },
    handleChange(val) {
      console.log(val);
    }
  }
}
</script>

<style scoped>
/deep/ .el-collapse {
  width: 100%;
}

/deep/ .el-collapse-item__header {
  background: #333333 !important;
  color: #FFFFFF !important;
  border-color: #666666 !important;
  font-size: 14px !important;
  padding: 20px 0 !important;

}
/deep/ .el-collapse-item__content {
  background: #333333 !important;
  color: #FFFFFF !important;
  padding: 10px 0 !important;
  border-color: #666666 !important;


}
/deep/ .el-collapse{
  border-top: #666666 !important;
  border-bottom: red !important;
}
/deep/ .el-collapse-item{
  padding: 10px 0 !important;
  background: #333333 !important;

}
/deep/ .el-collapse-item__wrap{
  border-bottom-color: #666666 !important;
}
/deep/ .el-collapse-item__content div:last-child {
  border: none !important;
  padding-bottom: 0 !important;
}
</style>
