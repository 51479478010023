<template>
  <div>
    <footer>
      <div class="container" v-if="screenWidth > 992">
        <div class="footer-links">
          <div style="display: flex;align-items: center;justify-content: space-between;width: 100%;height: 260px;padding-top: 40px">
            <div style="height: 100%;">
              <p style="font-weight: bold;font-size: 18px;color: white;padding-bottom: 10px;">关于我们</p>
              <div class="itemBox" style="color: #DDD;font-size: 14px">
                <div style="margin-bottom: 10px;cursor: pointer" @click="toPath('/',null,0)">公司介绍</div>
                <div style="margin-bottom: 10px;cursor: pointer" @click="toPath('/aboutWe',null,5)">加入我们</div>
                <div style="margin-bottom: 10px;cursor: pointer" @click="toPath('/connectionWe',null,5)">联系我们</div>
              </div>
            </div>
<!--            <div style="height: 100%;">
            <p style="font-weight: bold;font-size: 18px;color: white;padding-bottom: 10px;">主营业务</p>
              <div class="itemBox" style="color: #DDD;font-size: 14px">
                <div style="margin-bottom: 10px;cursor: pointer" @click="toPath('/Jiqiren?1',null,1)">数字机器人创新研发</div>
                <div style="margin-bottom: 10px;cursor: pointer" @click="toPath('/Jiqiren?2',null,1)">人工智能应用模型开发</div>
                <div style="margin-bottom: 10px;cursor: pointer" @click="toPath('/Jiqiren?3',null,1)">生态伙伴聚合与业务推广</div>
                <div style="margin-bottom: 10px;cursor: pointer" @click="toPath('/Jiqiren?4',null,1)">人才培养</div>
              </div>
            </div>-->
            <div style="height: 100%;">
              <p style="font-weight: bold;font-size: 18px;color: white;padding-bottom: 10px;">案例中心</p>
              <div class="itemBox" style="color: #DDD;font-size: 14px">
                <!-- <div style="margin-bottom: 10px;cursor: pointer" v-for="(item, index) in caseList" :key="index" @click="toPath('/SecureCenter',index+1,1)">{{item.dictLabel}}</div> -->
                <div style="margin-bottom: 10px;cursor: pointer" v-for="(item, index) in caseList" :key="index" @click="goSecure(item)">{{item.name}}</div>
              </div>
            </div>
<!--            <div style="height: 100%;">
              <p style="font-weight: bold;font-size: 18px;color: white;padding-bottom: 10px;">其他</p>
              <div class="itemBox" style="color: #DDD;font-size: 14px">
                <div style="margin-bottom: 10px;cursor: pointer">法律声明</div>
              </div>
            </div>-->
            <div style="height: 100%;">
              <p style="font-weight: bold;font-size: 18px;color: white;padding-bottom: 10px;">客服热线</p>
              <div style="color: #DDD;font-size: 14px">
                <div style="margin-bottom: 10px">028-801-92812</div>
              </div>
            </div>
            <div style="height: 100%;text-align: center">
              <div style="width: 133px;height: 133px;border-radius: 5px 5px 5px 5px;background: #FFFFFF;">
                <img style="" src="@/assets/imgs/erweima.png" alt="">
              </div>
              <p style="margin-top: 10px;font-size: 14px">- 关注我们 -</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <SidebarMenuItem :caseList="caseList"></SidebarMenuItem>
      </div>
    </footer>
    <div class="foot">
      <span>
        蜀ICP备2021000754号
      川公网安备51019002004539号
      </span>
    </div>
  </div>
</template>

<script>
import SidebarMenuItem from '@/components/SidebarMenuItem.vue';
import {caseCenterTypes} from "@/api/common";

export default {
  components:{SidebarMenuItem},
  // eslint-disable-next-line vue/multi-word-component-names
  name: "foot",
  props:['screenWidth','tabsIndex','tabsIndexChild','refsObj'],
  data(){
    return {
      drawerVisible: false,
      caseList:[],
    }
  },
  created() {
    // DictList('hj_case_center_type').then(res => {
    //   this.caseList = res.data.data
    // })
    this.getCaseCenterTypes()
  },
  methods: {
    async getCaseCenterTypes(){
      const res = await caseCenterTypes()
      this.caseList = res.data.data.filter(item=>item.pid == "202407291041440001")
    },
    goSecure(item){
      if(this.$route.path != '/SecureCenter'){
        this.$router.push({
          path:'/SecureCenter'
        })
      }
      setTimeout(() => {
        this.$bus.$emit('goSecure',item)
      }, 200);
    },
    toPath(url,index=null,tabsIndex=null){
      if (tabsIndex!==null) {
        this.$nextTick(()=>{
          this.$store.commit('setTabsIndex', tabsIndex);
        })
      }

      if (url.includes('?')){
        let lastChar = url[url.length - 1]
        this.$router.push({
          path:url,
          query: { type: lastChar,index: index }
        })
      }else{
        this.$router.push({
          path:url,
          query: { index: index }
        }).catch(err => {
          console.log('输出报错',err)
        })
      }
      setTimeout(()=>{
        // eslint-disable-next-line vue/no-mutating-props
        this.refsObj.showChild = false
      },50)
    }
  }
}
</script>
<style scoped lang="scss">
.foot{
  background: #1E1C18;
  font-size: 12px;
  text-align: center;
  padding: 10px 0;
  color: #FFFFFF;
  >span{
    display: inline-block;
    width: 45%;
    opacity: 0.7;
  }
}
footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
}
.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-links a {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
}
.footer-links img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .footer-links {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-links a {
    margin-bottom: 10px;
  }
}

.sidebar-menu-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.itemBox div {
  color: #999;
}
.itemBox div:hover {
  color: white;
}
</style>
