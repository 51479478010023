<template>
  <div>
    <div class="container-fluid" style="padding: 0">
      <!-- 大图 -->
      <div class="jumbotron mb-0 position-relative d-lg-block">
        <img v-if="screenWidth > 992" src="../assets/imgs/gongye.png" alt="" style="width: 100%;max-height: 640px">
        <img v-else src="../assets/imgs/gongye1.png" alt="" style="width: 100%;max-height: 640px">
      </div>
    </div>
    <div class="container">
      <!-- 居中标题 -->
      <div class="text-center py-3" v-if="screenWidth < 992">
        <div style="background: #F8F8F8;border-radius: 4px;display: flex;flex-direction: column;margin: 0 10px;padding: 20px;text-align: left">
          <div style="padding-bottom: 12px;">
            <div style="color:#1A1A1A;padding-bottom: 2px;">智能控制</div>
            <div style="color: #4D4D4D;font-size: 15px">包含嵌入式控制器、可编程逻辑控制器PLC、工控机、HMI人机界面、、机械臂等</div>
          </div>
          <div style="padding-bottom: 12px;">
            <div style="color:#1A1A1A;padding-bottom: 2px;">智能通信</div>
            <div style="color: #4D4D4D;font-size: 15px">包含工业级边缘计算网关、工业交换机、Lora、UWB、蓝牙等自组网设备及技术</div>
          </div>
          <div style="padding-bottom: 12px;">
            <div style="color:#1A1A1A;padding-bottom: 2px;">智能检测</div>
            <div style="color: #4D4D4D;font-size: 15px">包含AI视觉检测、激光扫描、智能手环、流量、电流、压力、电压等各类检测传感设备及技术</div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="sixBox" style="margin-top: 20px;">
        <div class="row" style="display: flex;justify-content: space-between">
          <div class="col-lg-4 col-md-2"
               v-for="(item,index) in 3" :key="index">
            <div class="card" style="border-radius: 4px;">
              <div class="image-container">
                <img src="@/assets/imgs/013.png" class="card-img-top" alt="Image" style="max-height: 200px;">
              </div>
              <div class="card-body">
                <h5 class="card-title text-truncate-1lines">大尺寸激光三维扫描检测</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="sixBox" style="margin-top: 40px;">
        <div class="container" :style="{ paddingTop: screenWidth < 992 ? '20px' : '60px' }">
          <div class="row" style="display: flex;justify-content: space-between">
            <div class="col-lg-3 col-md-6 card4"
                 v-for="(item,index) in 8" :key="index" style="min-height: 180px;">
              <div class="card" style="border-radius: 4px;margin-bottom: 20px">
                <div class="image-container">
                  <img src="@/assets/imgs/013.png" class="card-img-top" alt="Image" style="max-height: 200px;min-height: 120px;">
                </div>
                <div class="card-body">
                  <h5 class="text-truncate-1lines" style="font-weight: bold">大尺寸激光三</h5>
                  <p class="text-truncate-2lines">sadaxxxxxxxxsadaxxxxxxxxsadaxxxxxxxx</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <!-- 居中标题 -->
      <div class="text-center py-5" style="padding: 30px 0 50px 0 !important;">
        <h1 class="display-3 cencetTitle" style="font-size: 25px;font-weight: bold;color:#191919;">典型案例</h1>
      </div>
      <div class="row align-items-center justify-content-between contentBody" :style="[{ paddingBottom: screenWidth < 992 ? '30px' : '90px' },{ flexDirection: screenWidth < 992 ? 'column-reverse' : '' }]">
        <div class="col-lg-5 order-lg-1 order-2">
          <!-- 文字内容 -->
          <div class="text-truncate" style="margin-right: 16px">
            <img src="@/assets/imgs/yanjiuyuan.png" style="width: 340px;padding-bottom: 20px;" alt="">
            <div class="lead" style="white-space: pre-wrap;color:#666666;font-size: 15px;line-height: 2;" >
              <div style="color: #000;font-weight: bold;font-size: 17px;">航天五院518所，整星零件入库视觉检测系统</div>
              <div style="color: #5A5D66;font-size: 14px;">
                <div> 1、检测工件大多数表面为黑色哑光，尺寸范围为20×20×20mm到400×400×400mm。</div>
                <div>2、入库检测系统采用自动化测量软件实现对机器人、转台、扫描仪、分析软件进行控制，实现入库检测过程的自动化</div>
                <div>3、该系统由UR3协作机器人、GOCATOR 3210结构光扫描仪、测量基座与转台、检测控制软件和测量分析软件组成。</div>
                <div>4、扫描精度:精度可达0.035mm，检测工时:小于2分钟;</div>
                <div>5、一体式工作台安装，可以快速部署在生产线旁边，可以对</div>
                <div>6、生产加工件进行快速自动化检测和结果展示。</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 order-lg-2 order-1" v-if="screenWidth > 992">
          <div style="display: flex">
            <img src="@/assets/imgs/016.png" alt="右侧图片" class="img-fluid col-lg-6" style="max-width: 353px;margin-right: 28px;">
            <img src="@/assets/imgs/016.png" alt="右侧图片" class="img-fluid col-lg-6" style="max-width: 353px;">
          </div>
        </div>
        <div style="margin-top: 20px;" v-else>
          <div style="display: flex;justify-content: space-around">
            <img src="@/assets/imgs/016.png" alt="右侧图片" class="img-fluid col-lg-6 demoImg">
            <img src="@/assets/imgs/016.png" alt="右侧图片" class="img-fluid col-lg-6 demoImg">
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import {getInnovative, getModels, getBusiness, getTalent} from "@/api/common";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Jiqiren",
  data(){
    return {
      data:null,
      currentScreenWidth: null,
      dataObj:{
        list:[
          {
            briefImage:require('@/assets/imgs/ji1.png'),
            title:'数据处理类',
            content:'数字抓取信息采集数字比对表格作业'
          },
          {
            briefImage:require('@/assets/imgs/ji2.png'),
            title:'流程操作类',
            content:'多系统录入流程节点操作'
          },
          {
            briefImage:require('@/assets/imgs/ji3.png'),
            title:'文档生成类',
            content:'文书文档制作固定模板生成业务报告'
          },
          {
            briefImage:require('@/assets/imgs/ji4.png'),
            title:'自动执行类',
            content:'系统自动巡检告警事件自动监控'
          }
        ],
        mainBusiness:[
          {
            briefImage:require('@/assets/imgs/011.png'),
            title:'旭光科技',
            content:'成都旭光科技股份有限公司专注电子调谐器、无线通讯模块、MCU控制模块，产品广泛应用于视像行业、数据通信领域、移动通信领域，并在国产化信息技术领域专注解决民族产业卡脖子难题。装备设计辅助机器人项目通过自动录入规则，自动识别客户BOM数据，根据规则自动录入旭光ERP系统，将装备设计人员从重复低效的工作解放出来。'
          },
          {
            briefImage:require('@/assets/imgs/012.png'),
            title:'迈特航空',
            content:'成都迈特航空精密紧固件有限公司主要生产高温合金、铝合金、合金弹簧钢、碳素钢、不锈钢紧固件。是一家集科研、生产、检测于一体的高科技企业。\n' +
                '生产辅助机器人集群项目通过多个数字机器人互动协同，形成数字劳动力集群，为企业生产全业务链数字化转型智能化发展提供支撑。\n'
          },
          {
            briefImage:require('@/assets/imgs/013.png'),
            title:'瑞星丰泰精密电子公司',
            content:'成都瑞雪丰泰精密电子股份有限公司是一家以工艺技术研发为主体，生产制造为基础的超高精密电子零组件制造企业。\n' +
                '瑞雪丰泰数字机器人项目满足单位优化业务流程和规范化流程的需求，形成统一步骤自动化流程，为生成制造全业务链数字化转型智能化发展提供支撑。\n'
          }
        ]
      }

    }
  },
  watch:{
    '$route.query.type':{
      handler(newVal) {
        console.log('newVal',newVal == 1)
        // eslint-disable-next-line no-empty
        if(newVal == 1){
          getInnovative().then(res => {
            console.log('res',res.data)
            this.data = res.data
          })
        }
        // eslint-disable-next-line no-empty
        if(newVal == 2){
          getModels().then(res => {
            this.data = res.data
          })
        }
        // eslint-disable-next-line no-empty
        if(newVal == 3){
          getBusiness().then(res => {
            this.data = res.data
          })
        }
        // eslint-disable-next-line no-empty
        if(newVal == 4){
          getTalent().then(res => {
            this.data = res.data
          })
        }
      }
    }
  },
  created() {
    this.$store.commit('setTabsIndex', 1);
  },
  methods: {
    updateScreenDimensions() {
      this.currentScreenWidth = window.innerWidth;
    },
    toPath(url){
      this.$store.commit('setTabsIndex', 4);
      this.$router.push(url)
    },
  },
  mounted() {
    this.$store.commit('setTabsIndex', 0);
    this.updateScreenDimensions();
    window.addEventListener('resize', this.updateScreenDimensions);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenDimensions);
  },
  computed: {
    screenWidth() {
      return this.currentScreenWidth;
    },
  },
}
</script>

<style scoped lang="scss">
@media (min-width: 991px){
  .sixBox{
    //position: relative;
    //top: -90px;
    //margin-top: 20px;
  }

  .box4{
    .col-lg-3{
      width: 24%;
    }
    .card-img-top{
      max-height: 140px;
      border-radius: 10px 10px 0px 0px;
    }
  }
}
@media (max-width: 991px) {
  .changImg{
    max-width: 418px !important;
  }
  .sixBox{
    //position: relative;
    //top: -90px;
    .card4{
      width: 50%;
      .card{
        height: auto;
        background: white;
        .card-title{
          font-size: 14px;
        }
        .card-title1{
          font-size: 12px;
          margin-bottom: 8px;
        }
      }
      p{
        line-height: 1.2;
      }
    }
    .card4:nth-child(even){
      padding-left: 0px;
    }
  }
  .demoImg{
    width: 44%;
  }
}
.card-body{
  text-align: center;
  font-size: 16px;
  padding-bottom: 0px;
}
.card{
  border: none !important;
  background: #f7f7f7;
  margin-bottom: 50px;
}

.twoLine{
  font-size: 12px !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* 指定显示的行数 */
  max-height: 40px/* 这里需要设置合适的高度 */;
}
.card{
  cursor: pointer;
  .image-container{
    position: relative;
    display: inline-block; /* 使容器不占据整行 */
    overflow: hidden; /* 防止放大的图片超出容器 */
    border-radius: 10px;
    img{
      transition: transform 0.5s ease; /* 平滑的过渡效果 */
    }
  }
}
.card:hover {
  box-shadow: 20px 10px 20px -10px rgba(0, 0, 0, 0.1);
  img{
    transform: scale(1.3);
    z-index: 10;
  }
}

.cencetTitle{
  position: relative;
  font-weight: 500;
}
.cencetTitle::before{
  content: '';
  position: absolute;
  bottom: -13px !important;
  right: 48.5% !important;
  width: 30px !important;
  height: 4px;
  background: #C7000B;
  border-radius: 3px 3px 3px 3px;
}
</style>
