<template>
  <div id="app">
    <Head ref="head" :screenWidth="screenWidth" :tabsIndex="$store.state.tabsIndex" :tabsIndexChild="$store.state.tabsIndexChild" />
    <router-view :refsObj="refsObj"></router-view>
    <Foot :refsObj="refsObj" :tabsIndex="$store.state.tabsIndex" :tabsIndexChild="$store.state.tabsIndexChild" :screenWidth="screenWidth"></Foot>
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Foot from "@/components/foot.vue";
export default {
  name: 'App',
  components: {Foot, Head},
  data(){
    return {
      currentScreenWidth: null,
      refsObj:null,
    }
  },
  methods: {
    updateScreenDimensions() {
      this.currentScreenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.refsObj = this.$refs['head']
    this.updateScreenDimensions();
    window.addEventListener('resize', this.updateScreenDimensions);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenDimensions);
  },
  computed: {
    screenWidth() {
      localStorage.setItem('screenWidth',this.currentScreenWidth)
      return this.currentScreenWidth;
    },
  },
}
</script>

<style>
.text-truncate-1lines {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 限制显示行数为4 */
  -webkit-box-orient: vertical; /* 使文本垂直显示 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号代替隐藏的文本 */
}
.text-truncate-2lines {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制显示行数为4 */
  -webkit-box-orient: vertical; /* 使文本垂直显示 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号代替隐藏的文本 */
}
</style>
