import axios from "axios";

const instance = axios.create({
    // baseURL: 'http://121.36.84.109:8701',
    baseURL: 'http://8.137.15.241:8701',
    // baseURL: 'http://127.0.0.1:8080',
    // baseURL:'http://192.168.1.110:8080',
    headers: {
        // 'X-Litemall-Token' : localStorage.getItem("X-Litemall-Token")
    }
})

// 请求拦截器
// 1.请求拦截器，在发请求之前，请求拦截器可以检测到发起请求之前需要做什么事情，把需要做的事情，放在请求拦截器之前
axios.interceptors.request.use(config => {
    return config
})
// 响应拦截器
// 1.相应上面的请求拦截器的需求
// 2.把上面的请求拦截器的需求全部做到
axios.interceptors.response.use(res => {
    return res.data
})

// 对外暴露前面创建的构造方法
export default instance
