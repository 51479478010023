<template>
  <div>
    <div class="container-fluid" style="padding: 0">
      <div class="jumbotron mb-0 position-relative d-lg-block">
        <img v-if="screenWidth > 992" src="../assets/imgs/jieqiren.png" alt="" style="width: 100%;max-height: 640px">
        <img v-else src="@/assets/imgs/jiqiren1.png" alt="" style="width: 100%;max-height: 640px">
      </div>
    </div>
    <div class="container" v-if="screenWidth < 992">
      <div class="text-center py-5" style="padding: 40px 0 0px 0 !important;">
        <h1 class="display-4 cencetTitle" style="font-size: 25px;font-weight: bold;color:#191919;">大模型定义</h1>
        <p style="color:#5A5D66;margin-top: 40px;padding: 0 20px;font-size: 15px;">大模型全称为预训练大模型，是一套系统工程，包括模型资产、开发套件和工作流，是AI开发落地的新范式。</p>
      </div>
    </div>
    <div class="container" style="padding: 20px 0;">
      <img style="width: 100%" v-if="screenWidth > 992" src="@/assets/imgs/test1.png" alt="">
      <img style="width: 100%" v-else src="@/assets/imgs/test2.png" alt="">
    </div>


  </div>
</template>

<script>
import {getInnovative, getModels, getBusiness, getTalent} from "@/api/common";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Jiqiren",
  data(){
    return {
      data:null,
      currentScreenWidth: null,
      dataObj:{
        list:[
          {
            briefImage:require('@/assets/imgs/ji1.png'),
            title:'数据处理类',
            content:'数字抓取信息采集数字比对表格作业'
          },
          {
            briefImage:require('@/assets/imgs/ji2.png'),
            title:'流程操作类',
            content:'多系统录入流程节点操作'
          },
          {
            briefImage:require('@/assets/imgs/ji3.png'),
            title:'文档生成类',
            content:'文书文档制作固定模板生成业务报告'
          },
          {
            briefImage:require('@/assets/imgs/ji4.png'),
            title:'自动执行类',
            content:'系统自动巡检告警事件自动监控'
          }
        ],
        mainBusiness:[
          {
            briefImage:require('@/assets/imgs/011.png'),
            title:'旭光科技',
            content:'成都旭光科技股份有限公司专注电子调谐器、无线通讯模块、MCU控制模块，产品广泛应用于视像行业、数据通信领域、移动通信领域，并在国产化信息技术领域专注解决民族产业卡脖子难题。装备设计辅助机器人项目通过自动录入规则，自动识别客户BOM数据，根据规则自动录入旭光ERP系统，将装备设计人员从重复低效的工作解放出来。'
          },
          {
            briefImage:require('@/assets/imgs/012.png'),
            title:'迈特航空',
            content:'成都迈特航空精密紧固件有限公司主要生产高温合金、铝合金、合金弹簧钢、碳素钢、不锈钢紧固件。是一家集科研、生产、检测于一体的高科技企业。\n' +
                '生产辅助机器人集群项目通过多个数字机器人互动协同，形成数字劳动力集群，为企业生产全业务链数字化转型智能化发展提供支撑。\n'
          },
          {
            briefImage:require('@/assets/imgs/013.png'),
            title:'瑞星丰泰精密电子公司',
            content:'成都瑞雪丰泰精密电子股份有限公司是一家以工艺技术研发为主体，生产制造为基础的超高精密电子零组件制造企业。\n' +
                '瑞雪丰泰数字机器人项目满足单位优化业务流程和规范化流程的需求，形成统一步骤自动化流程，为生成制造全业务链数字化转型智能化发展提供支撑。\n'
          }
        ]
      }

    }
  },
  watch:{
    '$route.query.type':{
      handler(newVal) {
        console.log('newVal',newVal == 1)
        // eslint-disable-next-line no-empty
        if(newVal == 1){
          getInnovative().then(res => {
            console.log('res',res.data)
            this.data = res.data
          })
        }
        // eslint-disable-next-line no-empty
        if(newVal == 2){
          getModels().then(res => {
            this.data = res.data
          })
        }
        // eslint-disable-next-line no-empty
        if(newVal == 3){
          getBusiness().then(res => {
            this.data = res.data
          })
        }
        // eslint-disable-next-line no-empty
        if(newVal == 4){
          getTalent().then(res => {
            this.data = res.data
          })
        }
      }
    }
  },
  created() {
    this.$store.commit('setTabsIndex', 1);
  },
  methods: {
    updateScreenDimensions() {
      this.currentScreenWidth = window.innerWidth;
    },
    toPath(url){
      this.$store.commit('setTabsIndex', 4);
      this.$router.push(url)
    },
  },
  mounted() {
    this.$store.commit('setTabsIndex', 0);
    this.updateScreenDimensions();
    window.addEventListener('resize', this.updateScreenDimensions);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenDimensions);
  },
  computed: {
    screenWidth() {
      return this.currentScreenWidth;
    },
  },
}
</script>

<style scoped lang="scss">
@media (min-width: 991px){
  .card5{
    flex-basis: 48%;
    height: 278px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #EBEBEB;
    margin-bottom: 30px;
    .card{
      background: none !important;
      height: 85%;
      .card-body{
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .card-title{
          margin-top: 20px;
        }
        .bottomTitle{
          color: #666666;
          text-align: center;
          font-size: 13px;
        }
      }
    }
  }
  .card5:nth-child(even){
    margin-left: 20px;
  }
  .box4{
    .col-lg-3{
      width: 24%;
    }
    .card-img-top{
      max-height: 140px;
      border-radius: 10px 10px 0px 0px;
    }
  }
  .fenxiBox{
    position: relative;
    padding: 150px;
    padding-bottom: 220px;
    padding-top:50px;
    display: flex;
    >img{
      margin: 0 auto;
      width: 450px;
    }
  }
}
@media (max-width: 991px) {
  .changImg{
    max-width: 418px !important;
  }
  .box4{
    .card4{
      max-width: 40%;
      //height: 146px;
      padding: 20px !important;
      margin-bottom: 40px;
    }
    .card4:nth-child(even){
      padding-left: 0;
    }
    .card-title{
      font-size: 14px;
    }
    .card-img-top{
      height: 84px;
      border-radius: 10px 10px 0px 0px;
    }
  }
  .card5{
    flex-basis: 50%;
    margin-top: 25px;
    height: 140px;
    .card-title{
      font-size: 16px;
    }
    .bottomTitle{
      font-size: 13px;
      color: #5A5D66;
    }
    .card-body{
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .zhengBox{
      width: 35px;
      height: 35px;
      background: #E9E9E9;
      margin-bottom: 10px;
      border-radius: 0px 0px 0px 0px;
    }
  }
  .cencetTitle{
    position: relative;
    font-weight: 500;
  }
  .cencetTitle::before{
    content: '';
    position: absolute;
    bottom: -13px !important;
    right: 45.5% !important;
    width: 30px !important;
    height: 4px;
    background: #C7000B;
    border-radius: 3px 3px 3px 3px;
  }
  .lingyuBox{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    >div{
      width: 48%;
      padding-right: 10px;
      margin-bottom: 20px;
    }
    img{
      position: absolute;
      width: 50%;
      height: 280px;
      bottom: 50px;
      right: 0;
    }
  }
}
.zhengBox{
  width: 50px;
  height: 50px;
  background: #E9E9E9;
  border-radius: 0px 0px 0px 0px;
}
.card-body{
  text-align: center;
  font-size: 16px;
  padding-bottom: 0px;
}
.card{
  border: none !important;
  background: #f7f7f7;
  margin-bottom: 50px;
}
.cencetTitle{
  position: relative;
  font-weight: 500;
}
.cencetTitle::before{
  content: '';
  position: absolute;
  bottom: -17px ;
  right: 48.5% ;
  width: 30px;
  height: 4px;
  background: #C7000B;
  border-radius: 3px 3px 3px 3px;
}

.twoLine{
  font-size: 12px !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* 指定显示的行数 */
  max-height: 40px/* 这里需要设置合适的高度 */;
}
.card{
  cursor: pointer;
  .image-container{
    position: relative;
    display: inline-block; /* 使容器不占据整行 */
    overflow: hidden; /* 防止放大的图片超出容器 */
    border-radius: 10px;
    img{
      transition: transform 0.5s ease; /* 平滑的过渡效果 */
    }
  }
}
.card:hover {
  box-shadow: 20px 10px 20px -10px rgba(0, 0, 0, 0.1);
  img{
    transform: scale(1.3);
    z-index: 10;
  }
}
.card5 {
  cursor: pointer;
  .card{
    box-shadow: none;
  }
}
.card5:hover {
  box-shadow: 20px 20px 20px -10px rgba(0, 0, 0, 0.1);

}

</style>
