import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import AMapLoader from "@amap/amap-jsapi-loader";
import router from '@/router/index';
import store from './store/index.js';
import "@/assets/quill/quill.core.css";
import "@/assets/quill/quill.snow.css";
import "@/assets/quill/quill.bubble.css";
import {handleTree} from "@/utils/tree";

Vue.use(ElementUI);
AMapLoader.load({
  'key': '6b9116808497b81a7117bb0d360474dc',
  'version': '2.0',
  'plugins': ['AMap.Scale', 'AMap.GeoJSON']
}).then((AMap) => {
  // Vue.use(AMap)
  Vue.prototype.$AMap = AMap
})



Vue.config.productionTip = false

Vue.prototype.handleTree = handleTree
Vue.prototype.$bus = new Vue()
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
