<template>
  <div style="padding-bottom: 70px;">
    <div class="container-fluid" style="padding: 0">
      <div class="jumbotron mb-0 position-relative d-lg-block">
        <img src="../assets/imgs/009.png" alt="" style="width: 100%;max-height: 450px">
      </div>
    </div>

    <div class="container">
      <div class="text-center py-5" style="padding: 10px 0 10px 0 !important;margin-top: 40px;">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>关于我们</el-breadcrumb-item>
          <el-breadcrumb-item><span style="color:#C7000B;">联系我们</span></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="title">
        <span>联系我们</span>
      </div>
    </div>

    <div id="container" v-show="screenWidth >992" style="position: relative;">
      <div class="selectedBox">
        <div class="select" @click="openSelect">
          <span style="color: #999999;font-size: 14px;">{{ dataObj.name || '请选择' }}</span>
          <i class="el-icon-arrow-down"></i>
        </div>
        <div class="scroll-container" style="margin-top: 30px;">
          <div class="selectItem" v-if="selectShow">
            <div style="border-bottom: 1px solid #DEDEDE;cursor:pointer;padding-top: 30px;" v-for="(item, index) in dataList" :key="index" @click="select(item)">
              <p class="name">{{item.name}}</p>
              <div class="itemBox">
                <div>
                  <i class="el-icon-user"></i>
                  <span>联系人：{{item.ren}}</span>
                </div>
                <div>
                  <i class="el-icon-phone-outline"></i>
                  <span>联系方式：{{item.tel}}</span>
                </div>
                <div>
                  <i class="el-icon-message"></i>
                  <span>邮政：{{item.code}}</span>
                </div>
                <div>
                  <i class="el-icon-location-outline"></i>
                  <span>地址：{{item.address}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="selectedBox" v-show="screenWidth < 992">
<!--      <div class="select" @click="openSelect">
        <span style="color: #999999;font-size: 14px;">{{ dataObj.name || '请选择' }}</span>
        <i class="el-icon-arrow-down"></i>
      </div>-->
      <div class="selectItem" v-if="selectShow">
        <div style="border-bottom: 1px solid #DEDEDE;cursor:pointer;box-shadow: 0 9px 28px 8px rgba(0,0,0,.05),0 6px 16px rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12);padding: 30px;margin-bottom: 20px;" v-for="(item, index) in dataList" :key="index" @click="select(item)">
          <p class="name">{{item.name}}</p>
          <div class="itemBox">
            <div>
              <i class="el-icon-user"></i>
              <span>联系人：{{item.ren}}</span>
            </div>
            <div>
              <i class="el-icon-phone-outline"></i>
              <span>联系方式：{{item.tel}}</span>
            </div>
            <div>
              <i class="el-icon-message"></i>
              <span>邮政：{{item.code}}</span>
            </div>
            <div>
              <i class="el-icon-location-outline"></i>
              <span>地址：{{item.address}}</span>
            </div>
          </div>
        </div>
      </div>

    </div>



  </div>
</template>

<script>
import {getContactUs} from "@/api/common";

export default {
  data() {
    return {
      items: new Array(100).fill(null).map((_, index) => `Item ${index + 1}`),
      selectShow: true,
      optionsList: [
        { name: '华菁振兴（四川）', ren: '前台行政', tel:'028-801-92812', code:'611730', address:'四川省成都市高新区茂业中心C座24F' ,lng: 104.076448, lat: 30.671663},
        { name: '仁恒置地', ren: '前台行政', tel:'028-801-92812', code:'611730', address:'四川省成都市仁恒置地' ,lng: 104.067608, lat: 30.651186},
        { name: '振兴集团', ren: '前台行政', tel:'028-801-92812', code:'611730', address:'四川省成都市天府二街振兴集团' ,lng: 104.101921, lat: 30.65973},
      ],
      dataObj:{},
      map: null,
      currentScreenWidth: null,
      dataList:[],
    };
  },
  mounted() {
    getContactUs().then(res => {
      this.dataList = res.data.data.addressList;
      this.createMap();
      this.updateScreenDimensions();
      window.addEventListener('resize', this.updateScreenDimensions);
    })
  },
  methods: {
    openSelect(){
      this.selectShow = !this.selectShow
    },
    select(row){
      this.addMarker(row.name,row.lng,row.lat);
      this.dataObj = row
      // this.selectShow = false
    },
    createMap() {
      const that = this;
      const map = new that.$AMap.Map("container", {
        zoom: 16,
        viewMode: "2D",
        center: [103.858026,30.785305],
        features: ["road", "point"],
      });
      this.map = map;
      this.addMarker();
    },
    // eslint-disable-next-line no-unused-vars
    addMarker(content= "华菁振兴（四川）",lng=this.dataList[0].lng, lat=this.dataList[0].lat) {
      this.marker = new this.$AMap.Marker({//新增一个标记点
        position: [lng,lat],
      });
      this.marker.setMap(this.map); //放在map这个对象里面
      //顺便新增一个点击标记点出来的窗口
      this.infoWindow = new window.AMap.InfoWindow({//新增一个window窗口
        content: content, // 添加你想要显示的文字描述
        offset: new window.AMap.Pixel(0, -30), // 设置信息窗体偏移量，使其显示在标记点正上方
      });

      // 点击标记点时显示信息窗体
      this.marker.on("click", () => {
        this.infoWindow.open(this.map, this.marker.getPosition());
      });
      // 设置地图的中心点为标记点的经纬度
      this.map.setCenter([lng, lat]);
    },
    updateScreenDimensions() {
      this.currentScreenWidth = window.innerWidth;
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenDimensions);
  },
  computed: {
    screenWidth() {
      return this.currentScreenWidth;
    },
  },
  components: {},
  beforeDestroy() {
    this.map.destroy();
  }
};
</script>

<style lang = "less" scoped>
@media (min-width: 991px) {
  .title{
    position: relative;
    font-size: 34px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
    height: 100px;
  }
  .title span:after{
    content: '';
    position: absolute;
    left: 50%;
    top: 62px;
    transform: translateX(-50%);
    width: 70px;
    height: 4px;
    background: #c7000b;
    border-radius: 3px;
    margin: auto auto 80px;
  }
  #container {
    width: 70% !important;
    height: 800px;
    margin: 0 auto;
  }
  .selectedBox{
    position:absolute;
    top: 5%;
    left: 2%;
    z-index: 9999999;
  }
  .select{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 20px;
    cursor: pointer;
    width: 288px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px 1px rgba(26,62,153,0.1);
    border-radius: 8px 8px 8px 8px;
  }
  .selectItem{
    width: 288px;
    background: white;
    box-shadow: 0px 8px 20px 1px rgba(26,62,153,0.1);
    border-radius: 4px;
    padding: 28px 20px;
    .name{
      font-weight: 500;
      font-size: 18px;
      color: #C7000B;
    }
    .itemBox{
      display: flex;
      flex-direction: column;
      color: #333333;
      font-size: 15px;
      span {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }
  .itemBox{
    div {
      display: flex;
      margin-bottom: 10px;
      i{
        width: 15px;
      }
    }
  }
}
@media (max-width: 991px) {
  .title{
    position: relative;
    font-size: 26px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
    height: 60px;
  }
  .title span:after{
    content: '';
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    width: 70px;
    height: 4px;
    background: #c7000b;
    border-radius: 3px;
    margin: auto auto 80px;
  }
  .selectedBox{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .select{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 20px;
    cursor: pointer;
    //width: 100%;
    width: 350px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px 1px rgba(26,62,153,0.1);
    border-radius: 2px;
  }
  .selectItem{
    width: 385px;
    background: #fdfefe;
    border-radius: 2px;
    padding: 28px 20px;
    .name{
      font-weight: 500;
      font-size: 18px;
      color: #C7000B;
      position: relative;
      padding-left: 15px;
    }
    .name::before{
      content: '';
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 20px;
      border-radius: 10px;
      background: #C7000B;

    }
    .itemBox{
      display: flex;
      flex-direction: column;
      color: #333333;
      font-size: 15px;
      span {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }
  .itemBox{
    div {
      display: flex;
      margin-bottom: 10px;
      i{
        width: 15px;
      }
    }
  }
}
#container {
  width: 100%;
  height: 800px;
}

.scroll-container {
  width: 100%;
  max-height: 700px; /* 长盒子的最大高度，可以根据需要调整 */
  overflow-y: auto; /* 添加垂直滚动条 */
  margin: 0;
  padding: 0;
}

.item-box {
  height: 50px; /* 小盒子的高度，可以根据需要调整 */
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #e8e8e8; /* 添加边框以区分小盒子 */
}
</style>
