//该文件用于创建Vuex中最为核心的store

//引入Vue
import Vue from 'vue';
//引入Vuex
import Vuex from 'vuex';//引入插件并使用插件
Vue.use(Vuex); //使用插件后就可以在vm,vc里使用store配置项

//准备actions,用于响应组件中的动作
const actions = {};

//准备mutations,用于操作数据(state)
const mutations = {
    setTabsIndex(state, newIndex) {
        state.tabsIndex = newIndex;
        localStorage.setItem('tabsIndex', newIndex);
    },
    setTabsIndexChild(state, newIndex) {
        state.tabsIndexChild = newIndex;
        localStorage.setItem('tabsIndexChild', newIndex);
    }
};

//准备state,用于存储数据
const state = {
    tabsIndex:localStorage.getItem('tabsIndex')+'' || 0,
    tabsIndexChild:localStorage.getItem('tabsIndexChild')+'' || 0,
    dictList:[],
};

//创建store
const store = new Vuex.Store({
    actions: actions,
    mutations, //简写
    state //简写
});

//导出store
export default store;

