// router.js

import Vue from 'vue';
import VueRouter from 'vue-router';
import Jiqiren from '@/views/Jiqiren.vue';
import Jiqiren1 from '@/views/Jiqiren1.vue';
import Jiqiren2 from '@/views/Jiqiren2.vue';
import Jiqiren3 from '@/views/Jiqiren3.vue';
import Jiqiren4 from '@/views/Jiqiren4.vue';
import HomePage from '@/views/HomePage.vue';
import SecureCenter from '@/views/SecureCenter.vue';
import ExhibitionHall from '@/views/ExhibitionHall.vue';
import NewsConsult from '@/views/NewsConsult.vue';
import NewsConsultDetail from '@/views/NewsConsultDetail.vue';
import ZhiGaiZhuanShu from '@/views/ZhiGaiZhuanShu.vue';
import aboutWe from '@/views/aboutWe.vue';
import connectionWe from '@/views/connectionWe.vue';


Vue.use(VueRouter);

const routes = [
    { path: '/', component: HomePage },
    { path: '/Jiqiren', component: Jiqiren },
    { path: '/Jiqiren1', component: Jiqiren1 },
    { path: '/Jiqiren2', component: Jiqiren2 },
    { path: '/Jiqiren3', component: Jiqiren3 },
    { path: '/Jiqiren4', component: Jiqiren4 },
    { path: '/SecureCenter', component: SecureCenter },
    { path: '/ExhibitionHall', component: ExhibitionHall },
    { path: '/NewsConsult', component: NewsConsult },
    { path: '/NewsConsultDetail', component: NewsConsultDetail },
    { path: '/ZhiGaiZhuanShu', component: ZhiGaiZhuanShu },
    { path: '/aboutWe', component: aboutWe },
    { path: '/connectionWe', component: connectionWe },
];

const router = new VueRouter({
    routes,
    mode: 'history',
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0); // 将滚动位置设置为顶部
    next();
});
export default router;
