<template>
  <div style="background: #f8f8f8">
    <div class="container-fluid" style="padding: 0" v-if="screenWidth > 992">
      <div class="jumbotron mb-0 position-relative d-lg-block">
        <img src="../assets/imgs/060.png" class="bjimg" alt="">
      </div>
    </div>

    <div class="box4" style="background: #FFFFFF;padding: 0 20px;">
      <div class="container" :style="{ paddingTop: screenWidth < 992 ? '0px' : '20px' }">
<!--        <div class="text-center py-5" style="padding: 10px 0 10px 0 !important;">
          <el-breadcrumb separator="/" :style="{ fontSize: screenWidth < 992 ? '16' : '14px' }">
            <el-breadcrumb-item>新闻资讯</el-breadcrumb-item>
            <el-breadcrumb-item><span style="color:#C7000B;">新闻详情</span></el-breadcrumb-item>
          </el-breadcrumb>
        </div>-->

        <div v-if="HtmlContent">
          <h4 style="text-align: center;color: #000;font-weight: bold;">{{HtmlContent.projectName || HtmlContent.briefI || HtmlContent.title}}</h4>
          <div v-if="screenWidth < 992" style="padding-top: 10px;display: flex;justify-content: center;color: #999999;font-size: 13px;">
            <p>发布时间：{{ HtmlContent?.createTime || '' }}</p>
          </div>
          <div v-if="screenWidth > 992" style="padding: 20px 0;display: flex;justify-content: center;color: #999999;font-size: 13px;">
            <p>发布时间：{{ HtmlContent?.createTime || '' }}</p>
          </div>
          <div class="row html" style="display: flex;margin-top: 10px;align-items: center;flex-direction: column;justify-content: center;color:#666666;line-height: 1.8" v-html="HtmlContent.detailed || HtmlContent.content"></div>
        </div>
        <div class="bottomBox">
          <div class="bottomPage" @click="toOtherDetail(HtmlContent.newsPrevious)" v-if="HtmlContent?.prevTitle">
            <span>上一篇：</span>
            <span style="color:#000;">{{ HtmlContent?.prevTitle }}</span>
          </div>
          <div class="bottomPage" @click="toOtherDetail(HtmlContent.newsNext)" v-if="HtmlContent?.nextTitle">
            <span>下一篇：</span>
            <span style="color:#000;">{{ HtmlContent?.nextTitle }}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {caseCenter, detailId, getDetail} from '@/api/common'

export default {
  data(){
    return {
      activeName: 'second',
      currentPage1: 1,
      currentScreenWidth: null,
      HtmlContent:{},
    }
  },
  created() {
    let id = this.$route.query.id;
    if (this.$route.query.type || this.$route.query.type === 'news') {
      getDetail(id).then(res => {
        this.HtmlContent = res.data.data
      })
    } else if (this.$route.query.zgType) {
      detailId(id).then(res => {
        this.HtmlContent = res.data.data
      })
    } else {
      caseCenter(id).then(res => {
        this.HtmlContent = res.data.data
      })
    }

    /*if (!this.HtmlContent?.detailed || !this.HtmlContent?.content) {
      this.$nextTick(() => {
        this.HtmlContent.detailed = localStorage.getItem('NewsConsultDetailHtml')
        console.log(this.HtmlContent.detailed)
      })
    }*/
  },
  methods: {
    toOtherDetail(id){
      if (this.$route.query.type || this.$route.query.type === 'news') {
        getDetail(id).then(res => {
          this.HtmlContent = res.data.data
        })
      } else if (this.$route.query.zgType) {
        detailId(id).then(res => {
          this.HtmlContent = res.data.data
        })
      } else {
        caseCenter(id).then(res => {
          this.HtmlContent = res.data.data
        })
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    updateScreenDimensions() {
      this.currentScreenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.updateScreenDimensions();
    window.addEventListener('resize', this.updateScreenDimensions);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenDimensions);
  },
  computed: {
    screenWidth() {
      localStorage.setItem('screenWidth',this.currentScreenWidth)
      return this.currentScreenWidth;
    },
  },
}
</script>

<style scoped lang="scss">
@media (min-width: 991px){
  .bjimg{
    width: 100%;
    height: 300px !important;
  }
  .row{
    padding-bottom: 40px;
    border-bottom: 1px solid #DFE1E6;
  }
  .bottomBox{
    display: flex;
    align-items: center;
    padding: 14px 0;
    color: #999999;
    font-size: 14px;
  }
  .bottomPage{
    position: relative;
    padding-right: 30px;
    cursor: pointer;
  }
  .bottomPage:hover >span:nth-child(2) {
    color: #cd393e !important;
  }
  .bottomPage:nth-child(1):after{
    position: absolute;
    content: '';
    right: 13px;
    top: 1px;
    width: 1px;
    height: 18px;
    background: #e8e8e8;
  }
  .box4{
    position: relative;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 75%;
    border-radius: 10px;
    background: #FFFFFF;
    padding: 0 20px;
    .card-img-top{
      height: 134px;
      border-radius: 10px 10px 0px 0px;
    }
  }
}
@media (max-width: 991px) {
  .bjimg{
    width: 100%;
    height: 130px !important;
  }
  .row{
    padding-bottom: 0px;
    //border-bottom: 1px solid #DFE1E6;
  }
  .bottomBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 0;
    color: #999999;
    font-size: 14px;
    .bottomPage{
      position: relative;
      padding-right: 30px;
      cursor: pointer;
      width: 100%;
      padding: 4px 0;
    }
    .bottomPage:nth-child(1){
      padding-bottom: 14px;
    }
    .bottomPage:nth-child(2){
      padding: 10px 0;
      border-top: 1px solid #e7e7e7;
    }
  }
  .box4{
    .card4{
      max-width: 50%;
      height: 146px;
    }
    .card4:nth-child(even){
      padding-left: 0;
    }
    .card-title{
      font-size: 14px;
    }

  }
}


.card-body{
  text-align: center;
  font-size: 16px;
  padding-bottom: 0px;
}
.card{
  border: none !important;
  background: #f7f7f7;
  margin-bottom: 50px;
  cursor: pointer;
}
p{
  font-size: 14px;
}
::v-deep .el-tabs__active-bar{
  background: #C7000B;
}
::v-deep .is-active {
  color: #C7000B;
}
::v-deep .el-tabs__item:hover {
  color: #C7000B;
}
::v-deep .active{
  background: #C7000B !important;
}
.card:hover {
  box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.1);
  .card-title{
    color: #C7000B;
  }
}
.html{
  text-align: justify;
  /deep/ img{
    max-width: 100%;
  }
}
</style>
