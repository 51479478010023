var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid",staticStyle:{"padding":"0"}},[_c('div',{staticClass:"jumbotron mb-0 position-relative d-lg-block"},[(_vm.screenWidth > 992)?_c('img',{staticStyle:{"width":"100%","max-height":"640px"},attrs:{"src":require("../assets/imgs/gongye.png"),"alt":""}}):_c('img',{staticStyle:{"width":"100%","max-height":"640px"},attrs:{"src":require("../assets/imgs/gongye1.png"),"alt":""}})])]),_c('div',{staticClass:"container"},[(_vm.screenWidth < 992)?_c('div',{staticClass:"text-center py-3"},[_vm._m(0)]):_vm._e()]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"sixBox",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"row",staticStyle:{"display":"flex","justify-content":"space-between"}},_vm._l((3),function(item,index){return _c('div',{key:index,staticClass:"col-lg-4 col-md-2"},[_vm._m(1,true)])}),0)])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"sixBox",staticStyle:{"margin-top":"40px"}},[_c('div',{staticClass:"container",style:({ paddingTop: _vm.screenWidth < 992 ? '20px' : '60px' })},[_c('div',{staticClass:"row",staticStyle:{"display":"flex","justify-content":"space-between"}},_vm._l((8),function(item,index){return _c('div',{key:index,staticClass:"col-lg-3 col-md-6 card4",staticStyle:{"min-height":"180px"}},[_vm._m(2,true)])}),0)])])]),_c('div',{staticClass:"container"},[_vm._m(3),_c('div',{staticClass:"row align-items-center justify-content-between contentBody",style:([{ paddingBottom: _vm.screenWidth < 992 ? '30px' : '90px' },{ flexDirection: _vm.screenWidth < 992 ? 'column-reverse' : '' }])},[_vm._m(4),(_vm.screenWidth > 992)?_c('div',{staticClass:"col-lg-7 order-lg-2 order-1"},[_vm._m(5)]):_c('div',{staticStyle:{"margin-top":"20px"}},[_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#F8F8F8","border-radius":"4px","display":"flex","flex-direction":"column","margin":"0 10px","padding":"20px","text-align":"left"}},[_c('div',{staticStyle:{"padding-bottom":"12px"}},[_c('div',{staticStyle:{"color":"#1A1A1A","padding-bottom":"2px"}},[_vm._v("智能控制")]),_c('div',{staticStyle:{"color":"#4D4D4D","font-size":"15px"}},[_vm._v("包含嵌入式控制器、可编程逻辑控制器PLC、工控机、HMI人机界面、、机械臂等")])]),_c('div',{staticStyle:{"padding-bottom":"12px"}},[_c('div',{staticStyle:{"color":"#1A1A1A","padding-bottom":"2px"}},[_vm._v("智能通信")]),_c('div',{staticStyle:{"color":"#4D4D4D","font-size":"15px"}},[_vm._v("包含工业级边缘计算网关、工业交换机、Lora、UWB、蓝牙等自组网设备及技术")])]),_c('div',{staticStyle:{"padding-bottom":"12px"}},[_c('div',{staticStyle:{"color":"#1A1A1A","padding-bottom":"2px"}},[_vm._v("智能检测")]),_c('div',{staticStyle:{"color":"#4D4D4D","font-size":"15px"}},[_vm._v("包含AI视觉检测、激光扫描、智能手环、流量、电流、压力、电压等各类检测传感设备及技术")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",staticStyle:{"border-radius":"4px"}},[_c('div',{staticClass:"image-container"},[_c('img',{staticClass:"card-img-top",staticStyle:{"max-height":"200px"},attrs:{"src":require("@/assets/imgs/013.png"),"alt":"Image"}})]),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title text-truncate-1lines"},[_vm._v("大尺寸激光三维扫描检测")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",staticStyle:{"border-radius":"4px","margin-bottom":"20px"}},[_c('div',{staticClass:"image-container"},[_c('img',{staticClass:"card-img-top",staticStyle:{"max-height":"200px","min-height":"120px"},attrs:{"src":require("@/assets/imgs/013.png"),"alt":"Image"}})]),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"text-truncate-1lines",staticStyle:{"font-weight":"bold"}},[_vm._v("大尺寸激光三")]),_c('p',{staticClass:"text-truncate-2lines"},[_vm._v("sadaxxxxxxxxsadaxxxxxxxxsadaxxxxxxxx")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center py-5",staticStyle:{"padding":"30px 0 50px 0 !important"}},[_c('h1',{staticClass:"display-3 cencetTitle",staticStyle:{"font-size":"25px","font-weight":"bold","color":"#191919"}},[_vm._v("典型案例")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-5 order-lg-1 order-2"},[_c('div',{staticClass:"text-truncate",staticStyle:{"margin-right":"16px"}},[_c('img',{staticStyle:{"width":"340px","padding-bottom":"20px"},attrs:{"src":require("@/assets/imgs/yanjiuyuan.png"),"alt":""}}),_c('div',{staticClass:"lead",staticStyle:{"white-space":"pre-wrap","color":"#666666","font-size":"15px","line-height":"2"}},[_c('div',{staticStyle:{"color":"#000","font-weight":"bold","font-size":"17px"}},[_vm._v("航天五院518所，整星零件入库视觉检测系统")]),_c('div',{staticStyle:{"color":"#5A5D66","font-size":"14px"}},[_c('div',[_vm._v(" 1、检测工件大多数表面为黑色哑光，尺寸范围为20×20×20mm到400×400×400mm。")]),_c('div',[_vm._v("2、入库检测系统采用自动化测量软件实现对机器人、转台、扫描仪、分析软件进行控制，实现入库检测过程的自动化")]),_c('div',[_vm._v("3、该系统由UR3协作机器人、GOCATOR 3210结构光扫描仪、测量基座与转台、检测控制软件和测量分析软件组成。")]),_c('div',[_vm._v("4、扫描精度:精度可达0.035mm，检测工时:小于2分钟;")]),_c('div',[_vm._v("5、一体式工作台安装，可以快速部署在生产线旁边，可以对")]),_c('div',[_vm._v("6、生产加工件进行快速自动化检测和结果展示。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"}},[_c('img',{staticClass:"img-fluid col-lg-6",staticStyle:{"max-width":"353px","margin-right":"28px"},attrs:{"src":require("@/assets/imgs/016.png"),"alt":"右侧图片"}}),_c('img',{staticClass:"img-fluid col-lg-6",staticStyle:{"max-width":"353px"},attrs:{"src":require("@/assets/imgs/016.png"),"alt":"右侧图片"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('img',{staticClass:"img-fluid col-lg-6 demoImg",attrs:{"src":require("@/assets/imgs/016.png"),"alt":"右侧图片"}}),_c('img',{staticClass:"img-fluid col-lg-6 demoImg",attrs:{"src":require("@/assets/imgs/016.png"),"alt":"右侧图片"}})])
}]

export { render, staticRenderFns }