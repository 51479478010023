<template>
  <div>
    <div class="container-fluid" style="padding: 0">
      <!-- 大图 -->
      <div class="jumbotron mb-0 position-relative d-lg-block">
        <div class="d-flex justify-content-end align-items-center position-absolute w-100 h-100">
          <div class="col-12 col-md-11 col-lg-10 text-white p-4">
            <h2 class="titleH1" style="color: #252B3A;font-weight: 500">新闻资讯</h2>
            <p style="color:#000;font-size: 18px;display: flex;align-items: center">了解最新行业资讯、产业动态<img src="@/assets/imgs/Right.png" style="width: 18px;margin-left: 6px;" alt=""> </p>
          </div>
        </div>
        <img v-if="screenWidth > 992" src="../assets/imgs/news.png" alt="" style="width: 100%;max-height: 450px">
        <img v-else src="../assets/imgs/news1.png" alt="" style="width: 100%;max-height: 450px">
      </div>
    </div>

    <div class="container" :style="{ paddingTop: screenWidth < 992 ? '20px' : '20px' }">
      <!-- 居中标题 -->
      <div class="text-center py-5" style="padding: 10px 0 10px 0 !important;">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="item.dictLabel" :name="item.dictValue" v-for="(item,index) in tabsList" :key="index"></el-tab-pane>
        </el-tabs>
      </div>

      <div class="row" style="display: flex;justify-content: space-between;" :style="{ paddingTop: screenWidth < 992 ? '0px' : '20px' }" v-if="dataList.length">
        <div style="display: flex;align-items: center;border-bottom: 1px solid #DFE1E6;padding-bottom: 20px;">
          <img class="img-container img-fluid oneImg" style="border-radius: 10px 10px 10px 10px;max-width: 270px;" :src="dataList[0].briefImage" alt="">
          <div class="oneBox">
            <p class="text-truncate-1lines" style="font-weight: bold;font-size: 20px">{{dataList[0].title}}</p>
            <span :title="dataList[0].briefI" style="color:#4D4D4D;font-size: 14px" :class="[{'text-truncate-2lines':screenWidth > 992},{'text-truncate-3lines':screenWidth < 992}]">
              {{dataList[0].briefI}}
            </span>
            <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px">
              <span class="timerTitle">{{dataList[0].createTime.slice(0,10)}}</span>
              <div class="yuedu" style="" @click="toPath('/NewsConsultDetail',dataList[0].id)">阅读全文 <img src="@/assets/imgs/RightRed.png" alt=""></div>
            </div>
          </div>
        </div>
      </div>

      <div v-for="(item, index) in dataList" :key="index">
        <div @click="toPath('/NewsConsultDetail',item.id)" v-if="index > 0" class="row" style="display: flex;justify-content: space-between;" :style="{ paddingTop: screenWidth < 992 ? '0px' : '20px' }">
          <div class="otherBox">
            <div class="timer">
              <span style="color:#000;font-weight: bold;font-size: 33px;">{{parseDate(item.createTime).day}}</span>
              <span style="font-size: 32px;color:#DDDDDD;font-weight: 300;padding: 0 4px;"> / </span>
              <div style="color:#666666;font-size: 12px;display: flex;flex-direction: column;margin-left: 4px;margin-top: 6px;">
                <span>{{parseDate(item.createTime).month}}月</span>
                <span>{{parseDate(item.createTime).year}}</span>
              </div>
            </div>
            <div style="display: flex;flex-direction: column;padding-left: 30px;">
              <p style="font-weight: bold;font-size: 20px;margin-bottom: 6px;" v-if="screenWidth > 992">{{ item.title }}</p>
              <span style="color:#4D4D4D;font-size: 14px" class="text-truncate-2lines hoverRed">{{screenWidth > 992 ? item.briefI : item.title}}</span>
            </div>
          </div>
        </div>
      </div>

      <el-empty v-if="!dataList.length"></el-empty>


      <div class="block" v-if="total>pageSize">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage1"
            :page-size="pageSize"
            background
            layout="total, prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>
<script>

import {getNewsList, DictList} from "@/api/common";

  export default {
    data(){
      return {
        activeName: Number(localStorage.getItem('newsTabsIndex'))+1 + '' || 0,
        tabsList:[],
        currentPage1: 1,
        dataList:[],
        currentScreenWidth: null,
        total:0,
        index:Number(localStorage.getItem('newsTabsIndex'))+1 + '' || 1,
        pageNum:1,
        pageSize:10,
        dictType: 'hj_news_type',
      }
    },
    methods: {
      queryList(){
        getNewsList(this.index,this.pageNum,this.pageSize).then(res => {
          this.dataList = res.data.rows || []
          this.total = Number(res.data.total)
        })
      },
      toPath(url,query){
        this.$router.push({
          path:url,
          query: { id: query,type:'news' }
        })
      },
      parseDate(dateString) {
        const parts = dateString.match(/(\d{4})-(\d{2})-(\d{2})/);
        if (parts) {
          return {
            year: parts[1],
            month: parts[2],
            day: parts[3]
          };
        }
        return null;
      },
      updateScreenDimensions() {
        this.currentScreenWidth = window.innerWidth;
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.queryList();
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleClick(tab) {
        this.pageNum = 1;
        this.pageSize = 12;
        this.currentPage1 = 1
        this.index = Number(tab.index)+1 + ''
        localStorage.setItem('newsTabsIndex', tab.index)
        this.queryList();
      }
    },
    mounted() {
      DictList(this.dictType).then(res => {
        this.tabsList = res.data.data
      })
      this.queryList()

      this.updateScreenDimensions();
      window.addEventListener('resize', this.updateScreenDimensions);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateScreenDimensions);
    },
    computed: {
      screenWidth() {
        localStorage.setItem('screenWidth',this.currentScreenWidth)
        return this.currentScreenWidth;
      },
    },
  }
</script>
<style scoped lang="scss">
@media (min-width: 991px) {
  .oneBox{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: 49px
  }
  .otherBox{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DFE1E6;
    padding-bottom: 30px;
    padding-top: 10px;
  }
  .timerTitle{
    font-size: 16px;
    color: #999999
  }
  .yuedu{
    cursor: pointer;
    display: flex;
    color:#C7000B;
    align-items: center;
    width: 117px;
    height: 36px;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #C7000B;
    justify-content: center;
    font-size: 14px;
    >img{
      width: 15px;
    }
  }
  .block{
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
    margin-top: 100px;
  }
}
@media (max-width: 991px) {
  .oneImg{
    width: 150px !important;
  }
  .otherBox{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DFE1E6;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .timerTitle{
    font-size: 12px;
    color: #999999
  }
  .yuedu{
    cursor: pointer;
    display: flex;
    color:#C7000B;
    align-items: center;
    width: 84px;
    height: 28px;
    border-radius: 20px 20px 20px 20px;
    border: 1px solid #C7000B;
    justify-content: center;
    font-size: 12px;
    >img{
      width: 15px;
    }
  }
  .oneBox{
    display: flex;
    flex-direction: column;
    width: 60% !important;
    margin-left: 16px
  }
  .block{
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    margin-top: 30px;
  }
}
::v-deep .el-tabs__active-bar{
  background: #C7000B;
}
::v-deep .is-active {
  color: #C7000B;
}
::v-deep .el-tabs__item:hover {
  color: #C7000B !important;
}
::v-deep .active{
  background: #C7000B !important;
  color: #C7000B;
}
::v-deep .el-icon-arrow-left {
  color: red;
}
::v-deep .el-icon-arrow-right {
  color: red;
}
.timer{
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
  padding-right: 40px;
}
.timer::after {
  content: '';
  position: absolute;
  right: 0;
  display: inline-block;
  width: 2px;
  height: 56px;
  background: #DFE1E6;
}
.text-truncate-1lines {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 限制显示行数为4 */
  -webkit-box-orient: vertical; /* 使文本垂直显示 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号代替隐藏的文本 */
}
.text-truncate-2lines {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制显示行数为4 */
  -webkit-box-orient: vertical; /* 使文本垂直显示 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号代替隐藏的文本 */
}
.text-truncate-3lines {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 限制显示行数为4 */
  -webkit-box-orient: vertical; /* 使文本垂直显示 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号代替隐藏的文本 */
}
.otherBox{
  cursor: pointer;

}
.otherBox:hover .hoverRed{
  color: #C7000B !important;
}
</style>
