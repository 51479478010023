<template>
  <div>
    <div class="container-fluid" style="padding: 0">
      <!-- 大图 -->
      <div class="jumbotron mb-0 position-relative d-lg-block">
        <div class="d-flex justify-content-end align-items-center position-absolute w-100 h-100">
          <div class="col-12 col-md-11 col-lg-10 text-white p-4">
            <!-- 标题 -->
              <h2 class="titleH1" style="color: #252B3A;font-weight: 500;line-height:1.5">华为(四川)人工智能与智改数转创新推广中心<br>华为(西南)数字机器人创新中心</h2>
            <!-- 红色按钮 -->
            <button type="button" class="btn btn-danger mt-3 lianxi" style="background: #C7000B;border-radius: 0;margin-top: 4px;padding: 5px 20px" @click="toPath('/connectionWe')">联系我们</button>
          </div>
        </div>
        <img v-if="screenWidth > 992" src="../assets/imgs/home.png" alt="" style="width: 100%;max-height: 450px">
        <img v-else src="../assets/imgs/home1.png" alt="" style="width: 100%;max-height: 450px">
      </div>
    </div>

    <div class="container">
      <!-- 居中标题 -->
      <div class="text-center py-5">
        <h1 class="display-4 cencetTitle" style="font-size: 36px;font-weight: bold;color:#191919;">创新中心介绍</h1>
      </div>
      <!-- 主体内容区 -->
      <div class="row align-items-center justify-content-between contentBody" :style="{ paddingBottom: screenWidth < 992 ? '30px' : '90px' }">
        <div class="col-lg-6 order-lg-1 order-2">
          <!-- 文字内容 -->
          <div class="text-truncate" style="margin-right: 16px">
            <h2 class="display-4 titleH2" style="color: #191919;font-size: 24px;font-weight: bold;padding-bottom: 20px">基本介绍</h2>
            <div class="lead" style="white-space: pre-wrap;color:#666666;font-size: 15px;line-height: 2;" :style="{ marginBottom: screenWidth < 992 ? '20px' : '46px' }" :class="{'text-truncate-4lines':screenWidth < 992 && !expanded}">
              <span v-html="dataObj.introduce"></span>
            </div>
            <!-- 更多内容 -->
            <div style="display: flex;flex-direction: column;border-top: 1px solid #DFE1E6;border-bottom: 1px solid #DFE1E6;padding: 14px 0" v-if="screenWidth < 992 && expanded">
              <div style="color: #666666;font-size: 14px;display: flex;align-items: center;margin-bottom: 10px">
                <img src="@/assets/imgs/icon3.png" style="padding-right: 5px" alt="">
                <p style="margin-bottom: 0">地址：{{dataObj.companyAddress}}</p>
              </div>
              <div style="color: #666666;font-size: 14px;display: flex;align-items: center">
                <img src="@/assets/imgs/mail.png" style="padding-right: 5px" alt="">
                <p style="margin-bottom: 0">联系人：{{dataObj.companyPhone}}</p>
              </div>
            </div>
            <!-- 展开/收起按钮 -->
            <div style="position:relative; top: 0px;display: flex;justify-content: center;padding-bottom: 30px;margin-top: 20px">
              <div @click="toggleExpanded" v-if="screenWidth < 992 && !expanded" style="color: #252B39;font-size: 14px;">展开详情
                <img src="@/assets/imgs/xia.png" style="width: 18px;height: 18px;" alt=""></div>
              <div @click="toggleExpanded" v-if="screenWidth < 992 && expanded" style="">收起
                <img src="@/assets/imgs/shang.png" style="width: 13px;height: 13px;" alt=""></div>
            </div>
            <!-- 更多内容 -->
            <div style="display: flex;flex-direction: column;border-top: 1px solid #DFE1E6;padding-top: 28px" class="" v-if="screenWidth > 992">
              <div style="color: #666666;font-size: 14px;display: flex;align-items: center;margin-bottom: 10px">
                <img src="@/assets/imgs/icon3.png" style="padding-right: 5px" alt="">
                <p style="margin-bottom: 0">地址：{{dataObj.companyAddress}}</p>
              </div>
              <div style="color: #666666;font-size: 14px;display: flex;align-items: center">
                <img src="@/assets/imgs/mail.png" style="padding-right: 5px" alt="">
                <p style="margin-bottom: 0">联系人：{{dataObj.companyPhone}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 order-lg-2 order-1">
          <!-- 图片 -->
<!--          {{'http://192.168.1.55:8080' + dataObj.companyPicture}}-->
          <img v-if="dataObj.companyPicture!=undefined && dataObj.companyPicture.lastIndexOf('mp4') == -1" :src="dataObj.companyPicture" alt="右侧图片" class="img-fluid rounded liangceImg" style="margin-left:40px">
<!--          <video  class="img-fluid rounded liangceImg video1" style="margin-left:40px" controls>-->
<!--                <source :src="'http://121.36.84.109:8701' + dataObj.companyPicture" type="video/mp4">-->
<!--          </video>-->
<!--          <video v-else class="video1" src="../assets/test.mp4" controls></video>-->
          <video v-else class="video1" :src="dataObj.companyPicture" controls></video>
        </div>
      </div>
    </div>


<!--    <div class="box4" style="background: #f8f8f8;">
      <div class="container" :style="{ paddingTop: screenWidth < 992 ? '20px' : '60px' }">
        &lt;!&ndash; 居中标题 &ndash;&gt;
        <div class="text-center py-5">
          <h1 class="display-4 cencetTitle" style="font-size: 36px;font-weight: bold;color:#191919;">主营业务</h1>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 card4" v-for="(item,index) in dataObj.mainBusiness" :key="index" @click="toPath('/NewsConsultDetail',item.id,'zgsz')">
            <div class="card">
              <div class="image-container">
                <img :src="item.briefImage" class="card-img-top" alt="Image">
              </div>
              <div class="card-body">
                <h5 class="card-title">{{ item?.title }}</h5>
                <p class="card-text" v-html="item?.content"></p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>-->
<!--    <div class="box4" style="background: #f8f8f8;">
      <div class="container" :style="{ paddingTop: screenWidth < 992 ? '20px' : '60px' }">
        &lt;!&ndash; 居中标题 &ndash;&gt;
        <div class="text-center py-5">
          <h1 class="display-4 cencetTitle" style="font-size: 36px;font-weight: bold;color:#191919;">展厅展示</h1>
        </div>
        <div class="row">
          &lt;!&ndash; 大屏幕布局 &ndash;&gt;
          <div class="col-lg-4 col-md-6 card4" v-for="(item, index) in dataObj.benchmarkCase" :key="index" @click="toPath('/NewsConsultDetail', item.id)">
            <div class="card" style="height: 360px;">
              <div class="image-container">
                <img :src="item?.briefImage" class="card-img-top" alt="Image">
              </div>
              <div class="card-body">
                <h5 class="card-title">{{ item?.title }}</h5>
                <p class="card-text">{{ item?.briefI }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->

    <div class="container">
      <!-- 居中标题 -->
      <div class="text-center py-5">
        <h1 class="display-4 cencetTitle" style="font-size: 36px;font-weight: bold;color:#191919;">品牌活动</h1>
      </div>
      <!-- 主体内容区 -->
      <div class="row align-items-center justify-content-between contentBody" :style="{ paddingBottom: screenWidth < 992 ? '30px' : '90px' }" v-if="dataObj.cityEvents">
        <div class="col-lg-6 order-lg-1 order-2">
          <!-- 文字内容 -->
          <div class="text-truncate" style="margin-right: 16px">
            <h2 class="display-4 titleH2" style="color: #191919;font-size: 24px;font-weight: bold;padding-bottom: 10px">{{ dataObj.cityEvents[0]?.title }}</h2>
            <div class="lead" style="white-space: pre-wrap;color:#666666;font-size: 15px;line-height: 2;" :style="[{ marginBottom: screenWidth < 992 ? '20px' : '46px' },{ marginBottom: screenWidth < 992 && expanded1 ? '60px' : '20px' }]" :class="{'text-truncate-4lines':screenWidth < 992 && !expanded1}">
              <div @click="toPath('/NewsConsultDetail',dataObj.cityEvents[0].id)">
                <span v-html="dataObj.cityEvents[0]?.briefI"></span>
              </div>
              <div v-if="screenWidth > 992" @click="toPath('/NewsConsultDetail',dataObj.cityEvents[0].id,'news')" style="cursor: pointer;display:inline-block;position:relative;bottom: -14px;background: #C7000B;width: 105px;height: 29px;border-radius: 38px 38px 38px 38px;color:white;text-align: center;font-size: 14px;line-height: 29px;">了解更多 <img style="width: 20px;margin-bottom: 4px;" src="@/assets/imgs/swap-right@2x.png" alt=""></div>
            </div>
            <!-- 更多内容 -->
            <div style="display: flex;flex-direction: column;border-top: 1px solid #DFE1E6;border-bottom: 1px solid #DFE1E6;padding: 14px 0;position:relative;" v-if="screenWidth < 992 && expanded1">
              <div style="display:inline-block;cursor: pointer;position:absolute;bottom: 80px;background: #C7000B;width: 105px;height: 29px;border-radius: 38px 38px 38px 38px;color:white;text-align: center;font-size: 14px;line-height: 29px;" @click="toPath('/NewsConsultDetail',dataObj.cityEvents[0].id,'news')">了解更多 <img style="width: 20px;margin-bottom: 4px;" src="@/assets/imgs/swap-right@2x.png" alt=""></div>
              <div style="margin-bottom: 10px;display: flex;align-items: center">
                <span style="display: inline-block;width: 5px;height: 5px;background:#C7000B;margin-right: 8px;border-radius: 50%;margin-bottom: 2px"></span>
                <span style="color: #666666;font-size: 14px">{{ dataObj.cityEvents[0]?.address }}</span>
              </div>
            </div>
            <!-- 展开/收起按钮 -->
            <div style="position:relative; top: 0px;display: flex;justify-content: center;padding-bottom: 10px;margin-top: 20px">
              <div @click="toggleExpanded1" v-if="screenWidth < 992 && !expanded1" style="color: #252B39;font-size: 14px;">展开详情
                <img src="@/assets/imgs/xia.png" style="width: 18px;height: 18px;" alt=""></div>
              <div @click="toggleExpanded1" v-if="screenWidth < 992 && expanded1" style="">收起
                <img src="@/assets/imgs/shang.png" style="width: 13px;height: 13px;" alt=""></div>
            </div>
            <!-- 更多内容 -->
<!--            <div style="display: flex;flex-direction: column;border-top: 1px solid #DFE1E6;padding-top: 28px" class="" v-if="screenWidth > 992">
              <div style="margin-bottom: 10px;display: flex;align-items: center">
                <span style="display: inline-block;width: 5px;height: 5px;background:#C7000B;margin-right: 8px;border-radius: 50%;margin-bottom: 2px"></span>
                <span style="color: #666666;font-size: 14px">{{ dataObj.cityEvents[0]?.address }}</span>
              </div>
            </div>-->
          </div>
        </div>
        <div class="col-lg-6 order-lg-2 order-1">
          <!-- 图片 -->
          <img :src="dataObj.cityEvents[0]?.briefImage" alt="右侧图片" class="img-fluid rounded liangceImg">
        </div>
      </div>
      <div class="row align-items-center justify-content-between contentBody" v-if="dataObj.cityEvents" style="flex-direction: row-reverse;" :style="{ paddingBottom: screenWidth < 992 ? '30px' : '90px' }">
        <div class="col-lg-6 order-lg-2 order-1">
          <!-- 图片 -->
          <img :src="dataObj.cityEvents[1]?.briefImage" alt="右侧图片" class="img-fluid rounded liangceImg1">
        </div>
        <div class="col-lg-6 order-lg-1 order-2">
          <!-- 文字内容 -->
          <div class="text-truncate mr-44" >
            <h2 class="display-4 titleH2" style="color: #191919;font-size: 24px;font-weight: bold;padding-bottom: 10px">{{ dataObj.cityEvents[1]?.title }}</h2>
            <div class="lead" style="white-space: pre-wrap;color:#666666;font-size: 15px;line-height: 2;" :style="[{ marginBottom: screenWidth < 992 ? '20px' : '46px' },{ marginBottom: screenWidth < 992 && expanded2 ? '60px' : '20px' }]" :class="{'text-truncate-4lines':screenWidth < 992 && !expanded1}">
              <div @click="toPath('/NewsConsultDetail',dataObj.cityEvents[1].id)">
                <span v-html="dataObj.cityEvents[1]?.briefI"></span>
              </div>
              <div v-if="screenWidth > 992" style="cursor: pointer; display:inline-block;position:relative;bottom: -14px;background: #C7000B;width: 105px;height: 29px;border-radius: 38px 38px 38px 38px;color:white;text-align: center;font-size: 14px;line-height: 29px;" @click="toPath('/NewsConsultDetail',dataObj.cityEvents[1].id,'news')">了解更多 <img style="width: 20px;margin-bottom: 4px;" src="@/assets/imgs/swap-right@2x.png" alt=""></div>
            </div>
            <!-- 更多内容 -->
            <div style="display: flex;flex-direction: column;border-top: 1px solid #DFE1E6;border-bottom: 1px solid #DFE1E6;padding: 14px 0;position:relative;" v-if="screenWidth < 992 && expanded2">
              <div style="display:inline-block;position:absolute;bottom: 80px;background: #C7000B;width: 105px;height: 29px;border-radius: 38px 38px 38px 38px;color:white;text-align: center;font-size: 14px;line-height: 29px;" @click="toPath('/NewsConsultDetail',dataObj.cityEvents[1].id,'news')">了解更多 <img style="width: 20px;margin-bottom: 4px;" src="@/assets/imgs/swap-right@2x.png" alt=""></div>
              <div style="margin-bottom: 10px;display: flex;align-items: center">
                <span style="display: inline-block;width: 5px;height: 5px;background:#C7000B;margin-right: 8px;border-radius: 50%;margin-bottom: 2px"></span>
                <span style="color: #666666;font-size: 14px">{{ dataObj.cityEvents[1]?.address }}</span>
              </div>
            </div>
            <!-- 展开/收起按钮 -->
            <div style="position:relative; top: 0px;display: flex;justify-content: center;padding-bottom: 10px;margin-top: 10px">
              <div @click="toggleExpanded2" v-if="screenWidth < 992 && !expanded2" style="color: #252B39;font-size: 14px;">展开详情
                <img src="@/assets/imgs/xia.png" style="width: 18px;height: 18px;" alt=""></div>
              <div @click="toggleExpanded2" v-if="screenWidth < 992 && expanded2" style="">收起
                <img src="@/assets/imgs/shang.png" style="width: 13px;height: 13px;" alt=""></div>
            </div>
            <!-- 更多内容 -->
<!--            <div style="display: flex;flex-direction: column;border-top: 1px solid #DFE1E6;padding-top: 28px" class="" v-if="screenWidth > 992">
              <div style="margin-bottom: 10px;display: flex;align-items: center">
                <span style="display: inline-block;width: 5px;height: 5px;background:#C7000B;margin-right: 8px;border-radius: 50%;margin-bottom: 2px"></span>
                <span style="color: #666666;font-size: 14px">{{ dataObj.cityEvents[1]?.address }}</span>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>

<!--    <div class="box4" style="background: #f8f8f8;">-->
<!--      <div class="container" :style="{ paddingTop: screenWidth < 992 ? '20px' : '60px' }">-->
<!--        &lt;!&ndash; 居中标题 &ndash;&gt;-->
<!--        <div class="text-center py-5">-->
<!--          <h1 class="display-4 cencetTitle" style="font-size: 36px;font-weight: bold;color:#191919;">标杆案例</h1>-->
<!--        </div>-->
<!--        <div class="row">-->
<!--          &lt;!&ndash; 大屏幕布局 &ndash;&gt;-->
<!--          <div class="col-lg-4 col-md-6 card4"-->
<!--               v-for="(item, index) in dataObj.benchmarkCase" :key="index"-->
<!--                @click="toPath('/NewsConsultDetail',item.id)"-->
<!--          >-->
<!--            <div class="card" style="height: 360px;">-->
<!--              <div class="image-container">-->
<!--                <img :src="item?.briefImage" class="card-img-top" alt="Image">-->
<!--              </div>-->
<!--              <div class="card-body">-->
<!--                <h5 class="card-title">{{ item?.title }}</h5>-->
<!--                <p class="card-text">{{ item.briefI }}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->
    <div class="box4" style="background: #f8f8f8;">
      <div class="container" :style="{ paddingTop: screenWidth < 992 ? '20px' : '60px' }">
        <!-- 居中标题 -->
        <div class="text-center py-5">
          <h1 class="display-4 cencetTitle" style="font-size: 36px;font-weight: bold;color:#191919;">新闻资讯</h1>
        </div>
        <div class="row">
          <!-- 大屏幕布局 -->
          <div class="col-lg-35 col-md-6 card4 newsItem" v-for="(item, index) in dataObj.news" :key="index" @click="toPath('/NewsConsultDetail',item.id,'news')">
            <div class="card" style="height: 360px;">
              <div class="image-container">
                <img :src="item?.briefImage" class="card-img-top" alt="Image">
              </div>
              <div class="card-body" style="display: flex;justify-content: space-around;flex-direction: column">
                <h5 class="card-title">{{ item.title }}</h5>
                <div style="display: flex;align-items: center;">
                  <div style="display: flex;align-items: center;margin-right: 20px">
                    <img style="width: 20px;height: 20px;margin-bottom: 18px;margin-right: 6px" src="@/assets/imgs/dingwei.png" alt="">
                    <p style="color: #333333;font-size: 12px">{{item.address}}</p>
                  </div>
                  <div style="display: flex;align-items: center">
                    <img style="width: 15px;height: 15px;margin-bottom: 18px;margin-right: 6px" src="@/assets/imgs/riqi.png" alt="">
                    <p style="color: #333333;font-size: 12px;">{{item.time}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: center;margin-top: 20px" v-if="screenWidth > 992">
          <div style="cursor: pointer; display: flex;align-items: center;color: #C7000B;width: 184px;height: 44px;border: 1px solid #C7000B;border-radius: 30px;justify-content: center;" @click="toPath('/NewsConsult')">
            <div>更多新闻</div>
            <img style="width: 30px;margin-left: 6px" src="@/assets/imgs/swap-right-3.png" alt="">
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: center;" v-else @click="toPath('/NewsConsult')">
          <div style="display: flex;align-items: center;color: #C7000B;width: 124px;height: 34px;border: 1px solid #C7000B;border-radius: 30px;justify-content: center;">
            <div style="font-size: 14px">更多新闻</div>
            <img style="width: 24px;margin-left: 6px" src="@/assets/imgs/swap-right-3.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div style="background: #f8f8f8;padding-top: 30px;padding-bottom: 80px">
      <div class="container">
        <div class="text-center py-5">
          <h1 class="display-4 cencetTitle" style="font-size: 36px;font-weight: bold;color:#191919;">合作伙伴</h1>
        </div>
        <div v-if="screenWidth > 992">
          <div class="row" style="display: flex;align-items: center;flex-wrap: wrap">
            <div class="col-lg-3 col-md-6 mb-4 logoBotton" v-for="(item, index) in dataObj.partners" :key="index">
              <a :href="item.link" target="_blank">
                <img :src="item.logo" class="img-fluid" :alt="item.name">
              </a>
            </div>
          </div>
        </div>
        <div v-else>
          <div style="display: flex;align-items: center;margin-bottom: 20px;flex-wrap: wrap">
            <div v-for="(item, index) in dataObj.partners" :key="index" class="partnersItem"
                 style="min-width: 47.5%;height: 70px;background: white;display: flex;justify-content: center;align-items: center;border-radius: 6px;margin-bottom: 20px;">
              <img style="width: 110px;height: 34px;" :src="item.logo" class="img-fluid" :alt="item.name">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { gwHome } from '@/api/common';
export default {
  props:['refsObj'],
  data() {
    return {
      dataObj:{},
      expanded: false,
      expanded1: false,
      expanded2: false,
      currentScreenWidth: null,
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    toPath(url, query, str){
      if(url === '/connectionWe') {
        this.$store.commit('setTabsIndex', 4);
        setTimeout(()=>{
          // eslint-disable-next-line vue/no-mutating-props
          this.refsObj.showChild = false
        },0)
      }
      if (url === '/NewsConsult') this.$store.commit('setTabsIndex', 2);
      if(query){
        let obj = {}
        if (str === 'news') {
          obj.id = query
          obj.type = str
        }else if (str === 'zgsz'){
          obj.id = query
          obj.zgType = 200
        }else {
          obj.id = query
        }
        this.$store.commit('setTabsIndex', 2);
        this.$router.push({
          path:url,
          query: obj
        })
        // if (html) localStorage.setItem('NewsConsultDetailHtml', html);
      }else{
        this.$router.push(url)
      }
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    toggleExpanded1() {
      this.expanded1 = !this.expanded1;
    },
    toggleExpanded2() {
      this.expanded2 = !this.expanded2;
    },
    updateScreenDimensions() {
      this.currentScreenWidth = window.innerWidth;
    },
  },
  mounted() {
    gwHome().then(res => {
      this.dataObj = res.data.data
      console.log('this.dataObj',this.dataObj)
    })
    this.$store.commit('setTabsIndex', 0);
    this.updateScreenDimensions();
    window.addEventListener('resize', this.updateScreenDimensions);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenDimensions);
  },
  computed: {
    screenWidth() {
      return this.currentScreenWidth;
    },
  },
}
</script>

<style scoped lang="scss">
@media (min-width: 991px) {
  .video1{
    max-width:100%;
    height: 350px;
    border-radius: 10px;
  }
  .newsItem:nth-child(2){
    // margin: 0 32px;
  }
  .box4{
    .col-lg-3{
      .card-body{
        padding: 16px 14px !important;
      }
    }
  }
  .col-lg-35{
    width: 31.46%;
    flex: 0 0 auto;
    .card-body{
      text-align: left !important;
    }
  }
}
@media (max-width: 991px) {
  .video1{
    max-width:100%;
    height: 206px;
    border-radius: 10px;
  }
  .liangceImg{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .mr-44{
    margin-left: 0px !important;
  }
  .contentBody{
    flex-direction: column !important;
  }
  .titleH1 {
    font-size: 24px;
  }
  .titleH2{
    margin-top: 20px;
    color: #262a32;
    font-size: 18px !important;
    padding-bottom: 6px !important;
  }
  .lianxi{
    padding: 5px 20px !important;
    font-size: 13px !important;
    letter-spacing: 1.3px;
  }
  .row.align-items-center.justify-content-between {
    flex-direction: column-reverse;
  }
  .cencetTitle{
    font-size: 20px !important;
    font-weight: 500;
  }
  .cencetTitle::before{
    content: '';
    position: absolute;
    bottom: -17px !important;
    right: 46% !important;
    width: 30px !important;
    height: 4px;
    background: #C7000B;
    border-radius: 3px 3px 3px 3px;
  }
  .py-5{
    padding-top: 10px !important;
    padding-bottom: 2rem !important;
  }
  .card{
    border: none !important;
  }
  .card-body{
    text-align: left !important;
  }
  .card-title{
    color: #252B39 !important;
    font-size: 15.5px !important;
    font-weight: bold !important;
  }
}
//上面是小屏幕
.mr-44{
  margin-left: 44px;
}
.cencetTitle{
  position: relative;
  margin-bottom: 20px;
}
.cencetTitle::before{
  content: '';
  position: absolute;
  bottom: -24px;
  right: 48.5%;
  width: 36px;
  height: 4px;
  background: #C7000B;
  border-radius: 3px 3px 3px 3px;
}

.text-truncate-4lines {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* 限制显示行数为4 */
  -webkit-box-orient: vertical; /* 使文本垂直显示 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号代替隐藏的文本 */
}
.lianxi{
  border-radius: 3px !important;
}
.card {
  margin-bottom: 20px;
  height: 320px;
  flex: 1;
  border-color: #E6E6E6;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  position: relative; /* 用于定位放大的图片 */
  overflow: hidden; /* 防止放大的图片超出容器 */
  .image-container{
    position: relative;
    display: inline-block; /* 使容器不占据整行 */
    overflow: hidden; /* 防止放大的图片超出容器 */
    >img{
      transition: transform 0.5s ease; /* 平滑的过渡效果 */
      //height: auto;
    }
  }

}
.card:hover {
  box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.1);
  .image-container{
    >img{
      transform: scale(1.3);
      z-index: 10;
    }
  }

  .card-title{
    color: #C7000B;
  }
}
.card-deck {
  display: flex;
  flex-wrap: wrap;
}
.card-img-top{
  height: 170px;
}
.card-img-top3{
  height: 200px;
}
.card-body{
  text-align: center;
  .card-title{
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .card-text{
    line-height: 22px;
    font-size: 14px;
    color: #666666;
    text-transform: none;
  }
}
.logoBotton{
  width: 260px;
  height: 121px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  line-height: 121px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  img{
    width: 125px;
  }
}
.partnersItem:nth-child(even) {
  margin-left: 15px;
}
</style>
