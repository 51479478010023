<template>
  <div style="background: #f8f8f8" :style="{ height: screenWidth < 992 ? '2150px' : '100%' }">
    <div class="container-fluid" style="padding: 0">
      <div class="jumbotron mb-0 position-relative d-lg-block" style="position: relative;">
        <img src="../assets/imgs/060.png" class="bjimg" alt="">
        <div class="text-center position-absolute w-100 h-100 d-flex align-items-center justify-content-center" style="top: 20px;" v-if="screenWidth >992">
          <div class="title1">
            <span>概念简介</span>
<!--            <span>政策解读和能力介绍，华菁、创新中心对于智改数转的能力介绍，伙伴的能力介绍</span>-->
          </div>
        </div>
      </div>
    </div>
    <div class="box4" style="background: #FFFFFF;padding: 0 30px 20px 30px;">
      <div class="container" :style="{ paddingTop: screenWidth < 992 ? '20px' : '20px' }">
        <div class="row" content="topTitleBox">
          <span class="topTitle">{{ dataObj.conceptIntroduction }}</span>
<!--          <span class="topTitle">路演活动结束后，各业务团队根据所管理基金的行业属性和投资偏好，对不同项目提出了后续跟进的意向。其中，众信管理公司拟参与四川路桥定增项目，且将根据此项目选取相关优秀人才，组建项目组，以便更好地推动项目的落实与运行。业务发展部将根据各业务团队的实际需要，积极与项目方沟通，配合启动并开展上述工作。</span>-->
<!--          <span class="topTitle">根据所管理基金的行业属性和投资偏好，对不同项目提出了后续跟进的意向。其中，众信管理公司拟参与四川路桥定增项目，且将根据此项目选取相关优秀人才，组建项目组，以便更好地推动项目的落</span>-->
        </div>
      </div>
    </div>
    <div class="sixBox" v-if="dataObj.sixEstate">
      <div class="container" :style="{ paddingTop: screenWidth < 992 ? '20px' : '60px' }">
        <!-- 居中标题 -->
        <div class="text-center py-5" style="padding: 10px 0 10px 0 !important;">
          <h1 class="display-4 cencetTitle" style="font-size: 25px;font-weight: bold;color:#191919;">6大产业优势</h1>
          <div style="display: flex;justify-content: center;margin-top: 35px">
            <p style="color:#666666;font-size: 14px;text-align: center;width: 60%;" :class="{'twoLine':screenWidth < 992}">{{ dataObj.sixEstate.estateTitle }}</p>
          </div>
        </div>
        <div class="row" style="display: flex;justify-content: space-between">
          <div class="col-lg-4 col-md-6 card4"
               v-for="(item,index) in dataObj.sixEstate.sixList" :key="index"
               @click="toPath('/SecureCenter','zhigai',1,index)">
            <div class="card">
              <div class="image-container">
                <img :src="item.briefImage" class="card-img-top" alt="Image" style="max-height: 200px;">
              </div>
              <div class="card-body">
                <h5 class="card-title text-truncate-1lines">{{ item.title }}</h5>
                <div class="card-title1" style="white-space: nowrap">
                  <span>详情</span>
                  <img style="margin-bottom: 3px;width: 26px;margin-left: 6px;" src="@/assets/imgs/swap-right-3.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="boxNews" style="background: #f8f8f8;">
      <div class="container">
        <!-- 居中标题 -->
        <div class="text-center">
          <h1 class="display-4 cencetTitle" style="font-size: 32px;font-weight: bold;color:#191919;">新闻资讯</h1>
          <div style="display: flex;justify-content: center;margin-top: 35px">
            <p style="color:#666666;font-size: 14px;text-align: center;width: 60%;" :class="{'twoLine':screenWidth < 992}"></p>
          </div>
        </div>
        <div class="row">
          <!-- 大屏幕布局 -->
          <div class="col-lg-35 col-md-6 card4 col-lg-3.5" @click="toPathNews('/NewsConsultDetail',item.id)"
               :style="{ margin: screenWidth < 992 || index !== 1 ? ' 0' : '0 37px' }"
               v-for="(item,index) in dataObj.news" :key="index">
            <div class="card" style="max-height: 360px;">
              <div class="image-container">
                <img :src="item.briefImage" class="card-img-top3" alt="Image">
              </div>
              <div class="card-body" style="display: flex;justify-content: space-around;flex-direction: column">
                <h5 class="card-title">{{ item.title }}</h5>
                <div style="display: flex;align-items: center;;width: 100%;">
                  <div style="display: flex;align-items: center;margin-right: 20px">
                    <img class="icon1" style="width: 20px;height: 20px;margin-bottom: 18px;margin-right: 6px" src="@/assets/imgs/dingwei.png" alt="">
                    <p style="color: #333333;font-size: 12px">{{ item.address }}</p>
                  </div>
                  <div style="display: flex;align-items: center">
                    <img class="icon2" style="width: 15px;height: 15px;margin-bottom: 18px;margin-right: 6px" src="@/assets/imgs/riqi.png" alt="">
                    <p style="color: #333333;font-size: 12px;">{{ item.time }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="cursor: pointer; display: flex;align-items: center;justify-content: center;margin-top: 20px;padding-bottom: 80px;" v-if="screenWidth > 992" @click="toPath('/NewsConsult')">
          <div style="display: flex;align-items: center;color: #C7000B;width: 184px;height: 44px;border: 1px solid #C7000B;border-radius: 30px;justify-content: center;">
            <div>更多新闻</div>
            <img style="width: 30px;margin-left: 6px" src="@/assets/imgs/swap-right-3.png" alt="">
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: center;padding-bottom: 80px;" v-else>
          <div style="display: flex;align-items: center;color: #C7000B;width: 124px;height: 34px;border: 1px solid #C7000B;border-radius: 30px;justify-content: center;">
            <div style="font-size: 14px">更多新闻</div>
            <img style="width: 24px;margin-left: 6px" src="@/assets/imgs/swap-right-3.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getIdtd} from "@/api/common";

export default {
  data(){
    return {
      currentScreenWidth: null,
      dataObj:{},
    }
  },
  created() {
    getIdtd().then(res => {
      this.dataObj = res.data.data
    })
    this.$store.commit('setTabsIndex', 3);
  },
  methods: {
    toPath(url,query,tabIndex=null,id=null){
      if(query){
        let obj = {
          type: query,
          zgId:id
        }
        // eslint-disable-next-line no-unused-vars
        // if (url === '/NewsConsultDetail') obj = {id:query}
        this.$store.commit('setTabsIndex', tabIndex);
        this.$router.push({
          path:url,
          query: obj
        })
      }else{
        this.$store.commit('setTabsIndex', 2);
        this.$router.push(url)
      }
    },
    toPathNews(url,id){
      this.$router.push({
        path:url,
        query: {
          url,
          id,
          type: 'news'
        }
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    updateScreenDimensions() {
      this.currentScreenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.updateScreenDimensions();
    window.addEventListener('resize', this.updateScreenDimensions);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenDimensions);
  },
  computed: {
    screenWidth() {
      localStorage.setItem('screenWidth',this.currentScreenWidth)
      return this.currentScreenWidth;
    },
  },
}
</script>

<style scoped lang="scss">
@media (min-width: 991px){
  .topTitleBox {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-direction: column;
  }
  .topTitle{
    text-indent: 32px;
    color: #666666;
    font-size: 15px;
    padding-bottom: 20px;
  }
  .boxNews{
    .card-body1{
      padding: 16px 14px !important;
      .card-title{
        color: #1A1A1A;
        font-size: 22px;
        line-height: 1.4;
        padding-bottom: 10px;
      }
      .card-text{
        color: #666666;
        font-size: 15px;
      }
    }
    .col-lg-3{
      .card-body{
        padding: 16px 14px !important;
      }
    }
    .col-lg-35{
      width: 31.46%;
      flex: 0 0 auto;
      .card-body{
        text-align: left !important;
      }
    }
    .card-title{
      width: 100% !important;
    }
  }
  .sixBox{
    position: relative;
    top: -90px;
  }
  .cencetTitle{
    position: relative;
    font-weight: 500;
  }
  .cencetTitle::before{
    content: '';
    position: absolute;
    bottom: -13px !important;
    right: 48.5% !important;
    width: 30px !important;
    height: 4px;
    background: #C7000B;
    border-radius: 3px 3px 3px 3px;
  }
  .title1{
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    >span:nth-child(1){
      font-size: 28px;
      padding-bottom: 15px;
    }
    >span:nth-child(2){
      font-size: 13px;
      color: #252B3A;
      padding-bottom: 15px;
    }
  }
  .bjimg{
    width: 100%;
    height: 300px !important;
  }
  .box4{
    position: relative;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 75%;
    border-radius: 10px;
    .card-img-top{
      height: 134px;
      border-radius: 10px 10px 0px 0px;
    }
  }
}
@media (max-width: 991px) {
  .topTitleBox{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .topTitle{
    text-indent: 32px;
    color: #666666;
    font-size: 13px;
    padding-bottom: 20px;
  }
  .bjimg{
    width: 100%;
    height: 130px !important;
  }
  .cencetTitle{
    position: relative;
    font-weight: 500;
    margin-top: 70px;
    font-size: 20px !important;
  }
  .cencetTitle::before{
    content: '';
    position: absolute;
    bottom: -13px !important;
    right: 45.5% !important;
    width: 30px !important;
    height: 4px;
    background: #C7000B;
    border-radius: 3px 3px 3px 3px;
  }
  .box4{
    margin: auto;
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 0px 0 !important;
    .card4{
      max-width: 50%;
      height: 146px;
    }
    .card4:nth-child(even){
      padding-left: 0;
    }
    .card-title{
      font-size: 14px;
    }

  }
  .boxNews{
    position: relative;
    top: -170px;
    .card{
      background: white;
      height: 270px !important;
      margin-bottom: 20px;
      .card-title{
        font-size: 16px;
        width: 100%;
        margin-bottom: 10px;
        text-align: left;
      }
      .icon1{
        width: 16px !important;
        height: 16px !important;
      }
      .icon2{
        width: 14px !important;
        height: 14px !important;
      }
    }
  }
  .sixBox{
    position: relative;
    top: -90px;
    .card4{
      width: 50%;
      .card{
        height: 130px;
        background: white;
        .card-title{
          font-size: 14px;
        }
        .card-title1{
          font-size: 12px;
          margin-bottom: 8px;
        }
      }
    }
    .card4:nth-child(even){
      padding-left: 0px;
    }
  }
}

.card-title{
  font-size: 17px;
}
.card-title1{
  color: #a44148;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.card-body{
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 16px;
  padding-bottom: 0px;
}
.card{
  border: none !important;
  background: #f7f7f7;
  margin-bottom: 50px;
  cursor: pointer;
  .image-container{
    position: relative;
    display: inline-block; /* 使容器不占据整行 */
    overflow: hidden; /* 防止放大的图片超出容器 */
    border-radius: 10px;
    >img{
      transition: transform 0.5s ease; /* 平滑的过渡效果 */
      height: auto;
    }
  }
}

::v-deep .el-tabs__active-bar{
  background: #C7000B;
}
::v-deep .is-active {
  color: #C7000B;
}
::v-deep .el-tabs__item:hover {
  color: #C7000B;
}
::v-deep .active{
  background: #C7000B !important;
}
.card:hover {
  box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.1);
  .image-container{
    img{
      transform: scale(1.3);
      z-index: 10;
    }
  }
  .card-title{
    color: #C7000B;
  }
}

.text-truncate-1lines {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 限制显示行数为4 */
  -webkit-box-orient: vertical; /* 使文本垂直显示 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号代替隐藏的文本 */
}
</style>
