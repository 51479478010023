<template>
  <div style="position: sticky;top: 0;z-index: 999999999;">
    <!-- 导航栏 -->
    <nav class="navbar navbar-expand-lg navbar-light" style="height: 70px;z-index: 999999999;background: white">
      <div class="container">
        <!-- 左侧品牌标志 -->
        <a class="navbar-brand" href="#" @click="clickTab({path:'/',child:[]},0)">
          <img src="@/assets/imgs/092.png" alt="">
        </a>

        <!-- 切换按钮，当屏幕较小时显示 -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" style="border: none" @click="drawer = !drawer">
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- 导航项 -->
        <div class="collapse navbar-collapse" id="navbarNav" @mouseenter="clearTimer" @mouseleave="hideBox">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" style="position:relative;" v-for="(item, index) in tabsList" :key="index" @click="clickTab(item,index)">
              <span :class="{'tabsActive':tabsIndex==index && !isCHild}">{{ item.name }}</span>
              <div v-show="showChild && screenWidth > 992 && item.child && index === tabsIndex1" class="hoverBox"
                   @mouseenter="clearTimer" ref="box" @mouseleave="hideBox">
                <div v-for="(item1, indexChild) in item.child" :key="indexChild" @click.stop="toPath(item,indexChild,index)">
                  <router-link :to="item1.path" class="nav-link" :class="{'tabsActive':tabsIndexChild===indexChild}">{{ item1.name }}</router-link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!--        抽屉-->
    <el-drawer :visible.sync="drawer" title="x" :with-header="false" size="100%" direction="ttb" modal-class="transparent-drawer" :modal="false">
      <div style="margin-top: 70px; background: #F5F5F5;height: auto;margin-left: 20px;">
        <el-collapse v-model="activeNames" @change="handleChange">
          <div style="padding: 15px 0;background: white;border-bottom: 1px solid #E6E4EB;font-size: 14px" @click="toPathPhone('/',0)">首页</div>
          <el-collapse-item title="主营业务" name="1">
            <div @click="toPathPhone('/Jiqiren',1)" style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">数字机器人</div>
            <div @click="toPathPhone('/Jiqiren1',1)" style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">AI应用平台及开发</div>
            <div @click="toPathPhone('/Jiqiren2',1)" style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">工业智能感知与控制</div>
            <div @click="toPathPhone('/Jiqiren3',1)" style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">工业智慧生产系统及工具包</div>
            <div @click="toPathPhone('/Jiqiren4',1)" style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">诊断服务与人才培养</div>
          </el-collapse-item>
          <el-collapse-item title="案例中心" name="2">
            <!-- @click="toPathPhone1('/SecureCenter',1,index+1)" -->
            <div @click="goSecure(item)" style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a" v-for="(item,index) in caseList" :key="index">
              {{ item.name }}</div>
          </el-collapse-item>
<!--          <el-collapse-item title="智慧展厅" name="3">
            <div @click="toPathPhone('/ExhibitionHall?1',3)" style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">在线展厅（3D）</div>
            <div @click="toPathPhone('/ExhibitionHall?2',3)" style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">展厅预览</div>
          </el-collapse-item>-->
          <div style="padding: 15px 0;background: white;border-bottom: 1px solid #E6E4EB;font-size: 14px" @click="toPathPhone('/NewsConsult',4)">最新资讯</div>
<!--          <div style="padding: 15px 0;background: white;border-bottom: 1px solid #E6E4EB;font-size: 14px" @click="toPathPhone('/ZhiGaiZhuanShu',4)">智改数转</div>-->
          <el-collapse-item title="了解更多" name="4" style="padding: 10px 0 20px 0">
            <div @click="toPathPhone('/aboutWe',5)" style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">关于我们</div>
            <div @click="toPathPhone('/connectionWe',5)" style="margin-bottom: 10px;padding: 15px 0 15px 20px; border-bottom: 1px solid #5f5e5a">联系我们</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {caseCenterTypes} from "@/api/common";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Head',
  props:['screenWidth','tabsIndex','tabsIndexChild'],
  data(){
    return {
      activeNames:[],
      tabsList: [
        {name:'首页',path:'/',child:[]},
        {name:'主营业务',path:'',
          child:[
            {name:'数字机器人',path:'/Jiqiren'},
            {name:'AI应用平台及开发',path:'/Jiqiren1'},
            // {name:'工业智能感知与控制',path:'/Jiqiren2'},
            {name:'工业智慧生产',path:'/Jiqiren3'},
            {name:'数字诊断服务与人才培养',path:'/Jiqiren4'},
          ]
        },
        {name:'案例中心',path:'/SecureCenter',child:[]},
        /*{name:'智慧展厅',path:'',child:[
            {name:'在线展厅（3D）',path:'/ExhibitionHall?1'},
            {name:'展厅预览',path:'/ExhibitionHall?2'},
          ]},*/
        {name:'最新资讯',path:'/NewsConsult',child:[]},
        // {name:'智改数转',path:'/ZhiGaiZhuanShu',child:[]},
        {name:'了解更多',path:'',child:[
            {name:'关于我们',path:'/aboutWe'},
            {name:'联系我们',path:'/connectionWe'},
          ]},
      ],
      drawer: false,
      showChild: false,
      caseList:[],
      cout:0,
      isCHild:false,
      tabsIndex1:null
    }
  },
  watch: {
    'tabsIndexChild':{
      handler(newVal) {
        console.log(`${newVal}`);
      }
    },
    'tabsIndex':{
      handler() {
        // this.showChild = false
      }
    }
  },
  created() {
    this.getCaseCenterTypes()
  },
  methods: {
    handleChange(idx){
      this.activeNames = [idx[idx.length-1] + '']
    },
    async getCaseCenterTypes(){
      const res = await caseCenterTypes()
      this.caseList = res.data.data.filter(item=>item.pid == "202407291041440001")
    },
    goSecure(item){
      this.drawer = false
      if(this.$route.path != '/SecureCenter'){
        this.$router.push({
          path:'/SecureCenter'
        })
      }
      setTimeout(() => {
        this.$bus.$emit('goSecure',item)
      }, 200);
    },
    toPathPhone(url,index){
      if (this.$route.path !== url) {
        if (url.includes('?')){
          let lastChar = url[url.length - 1]
          this.$router.push({
            path:url,
            query: { type: lastChar }
          })
        } else{
          this.$router.push(url)
        }
      }
      this.$nextTick(()=>{
        this.$store.commit('setTabsIndex', index);
      })
      this.showChild = false
      this.drawer = false
    },
    toPathPhone1(url,tabsIndex,tabsChildIndex){
      this.drawer = false
      console.log('tabsChildIndex',tabsChildIndex)
      this.$nextTick(()=>{
        this.$store.commit('setTabsIndex', tabsIndex);
      })
      this.$router.push({
        path:url,
        query: { index: tabsChildIndex}
      }).catch(err => {
        console.log('输出报错',err)
      })
      this.showChild = false
    },
    clickTab(row,idx){
      if (idx === 0) {
        this.showChild = true
        this.drawer = false
        this.$store.commit('setTabsIndex', 0);
        this.$router.push({
          path:'/',
        }).catch(err => {
          console.log('输出报错',err)
        })
      }
      if (this.tabsIndex !== idx && this.$route.path !== row.path){
        if (row.path.length !== 0) {
          this.$router.push(row.path)
        }
      }
      console.log(this.showChild , idx === 5 || idx === 1, this.tabsIndex1)
      if (this.showChild && (idx === 5 || idx === 1)) {
        console.log('进入')
        this.showChild = false
      }else {
        // 这里修改判断第几个点击下拉二级
        idx === 4 || idx === 1 ? this.showChild = true : this.showChild = false
      }
      this.tabsIndex1 = idx
      this.$store.commit('setTabsIndexChild', -1);
      if (row?.child.length) {
        // this.isCHild = true
      }else{
        this.$nextTick(()=>{
          this.$store.commit('setTabsIndex', idx);
        })
        this.isCHild = false
      }

      console.log('this.showChild',this.showChild)
      // this.showChild ?this.showChild = !this.showChild
    },
    toPath(row,idx,tabsIndex=null) {
      this.$nextTick(()=>{
        this.$store.commit('setTabsIndex', tabsIndex);
      })
      this.$store.commit('setTabsIndexChild', idx);
      this.showChild = false
      this.isCHild = false
    },
    clearTimer() {
      if (this.hideTimer) {
        clearTimeout(this.hideTimer);
        this.hideTimer = null;
      }
    },
    hideBox() {
      // this.hideTimer = setTimeout(() => {
      //   if (this.showChild) {
      //     console.log('123123')
      //     if (this.$refs.box && this.$refs.box.length) {
      //       this.$refs.box[this.$refs.box.length-1].style.display = 'none';
      //     }
      //   }
      // }, 1000);
    },
  }
}
</script>

<style scoped lang="scss">
.hoverBox{
  position: absolute;
  left: -30px;
  top: 45px;
  width: 180px;
  background: white;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  >div{
    padding: 10px 0px;
    white-space: nowrap;
  }
  >div:hover{
    color: #C7000B !important;
    background: RGBA(252, 242, 242, 0.5);
  }
}
/* 自定义样式 */
.container{
  max-margin: 0 215px;
}
@media (min-width: 991px) {
  .navbar-brand {
    >img{
      width: 350px;
    }
    margin-right: 24px;
  }
}
@media (max-width: 991px) {
  .navbar-brand {
    >img{
      width: 280px;
    }
    margin-right: 24px;
  }
}

.navbar-nav {
  .nav-item{
    text-align: center;
    width: 110px;
    cursor: pointer;
    font-size: 15px;
    color: #191919;
  }
  .nav-link:hover {
    color: #C7000B !important;
  }
}
 ::v-deep .el-drawer__body {
  padding: 0 !important;
}
 .H5-nav-item {
   display: flex;
   flex-direction: column;
   margin: 0 30px;
   min-height: 75px;
   line-height: 75px;
   border-bottom: 1px solid #E6E4EB;
   .H5-nav-link {
      color: #333333;
     font-size: 20px;
     cursor: pointer;
     border-bottom: 1px solid #E6E4EB;
   }
   .H5-nav-item-child {
     margin: 0 30px;
     cursor: pointer;
     border-bottom: 1px solid #E6E4EB;
     .H5-nav-link-chid {
       font-size: 16px;

     }
   }
   .H5-nav-item-child:last-child{}

    //border: none;

 }
::v-deep .el-collapse-item:last-child div {
  background: #f6f6f6 !important;
}
::v-deep .el-collapse {
  border-bottom: none !important;
}
::v-deep .el-collapse >div {
  background: #f6f6f6 !important;
}
::v-deep .el-collapse-item__header {
  background: #f6f6f6 !important;
}
::v-deep .el-collapse-item__content {
  background: #f6f6f6 !important;
  padding-bottom: 1px;
}
::v-deep .el-collapse-item__content >div {
  border-color: #e7e7e7 !important;
  background: #f6f6f6 !important;
}

::v-deep .el-collapse-item__wrap:last-child{
  border: none !important;
}
::v-deep .el-collapse-item{
  padding: 4px 0;
  background: #f6f6f6;
}

::v-deep .el-collapse-item:last-child div{
  padding-bottom: 10px !important;
  //border: none !important;
}

 .tabsActive {
   color: #C7000B !important;
 }

 ::v-deep .el-drawer {
   background: #f6f6f6 !important;
 }
</style>
