import request from '@/utils/request'

//官网首页
export function gwHome() {
    return request({
        method:'GET',
        url:'/public/index/detail'
    })
}

//案例列表
export function CaseCenterList(type,pageNum,pageSize) {
    return request({
        method:'GET',
        url:"/public/caseCenter/CaseCenterList",
        params: {
            type: type,
            pageNum:pageNum,
            pageSize:pageSize
        },
    })
}

//案例中心
export function caseCenter(id) {
    return request({
        method:'GET',
        url:`/public/caseCenter/${id}`,

    })
}

//案例中心类型
export function caseCenterTypes() {
    return request({
        method:'GET',
        url:`/public/caseCenter/types`,

    })
}

//字典查询
export function DictList(dictType) {
    return request({
        method:'GET',
        url:`/public/dict/type/${dictType}`,
    })
}

//新闻列表
export function getNewsList(type,pageNum,pageSize) {
    return request({
        method:'GET',
        url:`/public/news/getNewsList/${type}`,
        params: {
            pageNum:pageNum,
            pageSize:pageSize
        },
    })
}
// 获取收藏
export function getDetail(id) {
    return request({
        method:'GET',
        url:`/public/news/getDetail/${id}`,
    })
}
// 删除收藏
export function Deletdcollect(arr) {
    return request({
        method:'post',
        data: {
            id:arr
        },
        url:`/collect/delete`,
    })
}

//获取数字机器人创新研发
export function getInnovative() {
    return request({
        method:'get',
        url:`/public/config/getInnovative`,
    })
}

// 人工智能应用模型开发
export function getModels() {
    return request({
        method:'get',
        url:`/public/config/getModels`,
    })
}
//获取生态伙伴聚合与业务推广
export function getBusiness() {
    return request({
        method:'get',
        url:`/public/config/getBusiness`,
    })
}
//获取人才培养
export function getTalent() {
    return request({
        method:'get',
        url:`/public/config/getTalent`,
    })
}
//获取关于我们
export function getAboutUs() {
    return request({
        method:'get',
        url:`/public/config/getAboutUs`,
    })
}
//获取联系我们
export function getContactUs() {
    return request({
        method:'get',
        url:`/public/config/getContactUs`,
    })
}
//获取智改数转
export function getIdtd() {
    return request({
        method:'get',
        url:`/public/itdt/getIdtd`,
    })
}
//通用详细查询List
export function detailList(type,pageNum,pageSize) {
    return request({
        method:'get',
        url:`/public/detail/list`,
        params:{
            type,
            pageNum,
            pageSize
        }
    })
}
//通用详细Id查询
export function detailId(id) {
    return request({
        method:'get',
        url:`/public/detail/${id}`,
    })
}
