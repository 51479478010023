<template>
  <div style="background: #f8f8f8;">
    <div class="container-fluid" style="padding: 0">
      <div class="jumbotron mb-0 position-relative d-lg-block">
        <img src="../assets/imgs/060.png" class="bjimg" alt="">
      </div>
    </div>
    <div class="titleTop1"  v-if="zgId">
      <span v-if="screenWidth > 992" class="abc" style="top: 40px;">{{ activeList[zgId].dictLabel }}</span>
      <span v-else>{{ activeList[zgId].dictLabel }}</span>
    </div>
    <div class="box4 zhigaiBox" style="background: #FFFFFF;padding: 0 30px 30px 30px;margin-bottom: 0px;" v-if="type && zgId" :style="{'top':type?'-100px':'-0px'}">
      <div class="container" :style="{ paddingTop: screenWidth < 992 ? '20px' : '20px' }">
        <div class="row" content="topTitleBox" style="border-bottom: none;font-size: 14px;color:#666;">
          <span class="topTitle">{{ zgszListTitle[zgId] }}</span>
        </div>
      </div>
    </div>
    <div class="box4" style="background: #FFFFFF;position: relative" :style="[{'top':type && screenWidth > 992 ?'10px':'-20px'},{'top':!type && screenWidth > 992 ?'-80px !important':'0px !important'}]">
      <div class="titleTop" v-if="!zgId">
        <span v-if="screenWidth > 992" class="abc">案例中心</span>
        <span v-else>案例中心</span>
      </div>
      <div class="container" :style="{ paddingTop: screenWidth < 992 ? '20px' : '0' }">
        <!-- 居中标题 -->
        <div class="text-center py-5" style="padding: 10px 0 10px 0 !important;" v-if="!zgId">
          <el-tabs v-model="activeName" @tab-click="handleClick" class="tab-one">
            <el-tab-pane v-for="(item, index) in activeList" :key="index"  :name="JSON.stringify(item)">
              <el-dropdown  slot="label" @command="handleCommand" v-if="item.children!=undefined&&item.children.length>0">
                <span class="el-dropdown-link">
                  {{ showTabLabel }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" v-if="item.children!=undefined&&item.children.length>0">
                  <el-dropdown-item v-for="child in item.children" :key="child.id" :command="child">{{child.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <span slot="label" v-else>{{ item.name }}</span>
            </el-tab-pane>
          </el-tabs>
<!--          <el-tabs v-model="activeName_one" @tab-click="handleClick">-->
<!--            <el-tab-pane v-for="(item_one, index_one) in activeList_one" :key="index_one" :label="item_one.label"></el-tab-pane>-->
<!--          </el-tabs>-->
        </div>
        <div class="row" style="display: flex;margin-top: 20px">
          <div class="col-lg-3 col-md-6 card4" @click="toPath('/NewsConsultDetail',item.id)" v-for="(item, index) in dataList" :key="index">
            <div style="position: absolute;top: -5px;left: 30px;z-index: 999" v-if="index<3&&pageNum===1">
              <img style="width: 50px;height: 44px;" src="@/assets/imgs/biaogan.png" alt="">
            </div>
            <div class="card">
              <div class="image-container">
                <img style="height: 100%;" :src="item.briefImage" class="card-img-top" alt="Image">
              </div>
              <div class="card-body">
                <h5 class="card-title text-truncate-1lines">{{ item.title }}</h5>
              </div>
            </div>
          </div>
          <el-empty v-if="!dataList.length"></el-empty>
        </div>

        <div class="block" style="display: flex;justify-content: center;padding-bottom: 30px" v-if="total>pageSize">
          <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="pageSize"
              background
              layout="total, prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import {CaseCenterList, detailList,caseCenterTypes} from '@/api/common'

export default {
  name:'SecureCenter',
  data(){
    return {
      activeList:[{label:'政府',name:'one'},{label:'金融',name:'two'},{label:'医疗',name:'three'},{label:'教育',name:'four'},{label:'互联网',name:'five'}],
      activeList_one:[{label:'政府',name:'one'},{label:'金融',name:'two'},{label:'医疗',name:'three'},{label:'教育',name:'four'},{label:'互联网',name:'five'}],
      activeName: Number(localStorage.getItem('centerTabsIndex'))+1 + '' || 0,
      activeName_one: 0,
      currentPage1: 1,
      type: null,
      dataList:[],
      total:0,
      index:Number(localStorage.getItem('centerTabsIndex'))+1 + '' || 1,
      pageNum:1,
      pageSize:12,
      dictType: 'hj_case_center_type',
      currentScreenWidth: null,
        zgszListTitle:[
          '四川电子信息产业总量大、贡献多，在全国具有一定的影响力。总体考虑是要把新型显示打造成世界级产业，培育集成电路、智能终端、软件与信息服务业3个国家级产业，壮大先进计算和存储、卫星网络、智能传感器、人工智能、信息安全等新兴产业，前瞻布局量子信息、第三代半导体、6G通信、车联网和元宇宙等未来产业，建成世界级电子信息产业集群。',
          '装备制造是工业的心脏和国民经济的生命线。四川是全国重要的动力设备制造基地、航空装备研制基地、动力电池生产基地。未来五年，将重点打造清洁能源装备、航空航天、汽车及动力电池、轨道交通等世界级高端优势产业集群，建设机器人、智能检测装备、高档数控机床、核技术应用装备等国家级新兴战略研发创新高地，培育节能环保装备、现代农业装备、应急产业装备等西部领先行业特色装备研制基地，构建国家级“装备+服务”深度融合先行地',
          '食品轻纺是重要民生产业，也是四川传统优势产业。未来五年，将着力培育1个世界级优质白酒产业集群，打造优质白酒、粮油加工、新型竹产业、肉制品加工、纺织服装、家具制造、调味品制造、预制菜、川烟等千亿规模产业，形成一批营收超百亿企业和国际国内知名品牌。',
          '四川已经建成了全国最大的清洁能源基地、全国最大的现代化天然气（页岩气）生产基地、全国最大的天然气化工生产和研发基地。未来五年，将重点围绕清洁能源、绿色化工、节能环保三大领域，着力打造国家清洁能源战略基地、全国能源化工产业践行新发展理念创新实践区，建成具有全国影响力的能源化工产业先进制造集群。',
          '四川在钒钛、锂电、晶硅光伏、稀土等领域特色优势明显。未来五年，重点是要做大钒钛、稀土、锂电、晶硅光伏等特色优势材料，做强先进钢铁材料、新型建筑材料、化工新材料、有色金属材料等先进基础材料，做精新型显示材料、半导体材料、生物医用材料等关键战略材料，布局3D打印材料、智能材料、纳米材料、量子材料等前沿新材料，打造全球有重要影响力的先进材料产业高地。',
          ' 成渝医药健康产业圈是继长三角、珠三角、环渤海湾的第四大医药健康创新发展集聚区。下一步，将重点发展生物制药、现代中药、高端化药、高性能医疗器械、核医药等特色优势领域，加快发展智能健康装备、特色健康产品、高原健康用氧、新型医药流通等新兴领域，建成全国领先、世界一流的医药研发创新体系，综合实力进入全国第一方阵。',
        ],
      zgId:null,
      showTabLabel:undefined
    }
  },
  watch:{
    '$route.query.index': {
      handler(newVal, oldVal) {
        console.log(`Query parameter 'index' changed from ${oldVal} to ${newVal}`);
        this.$store.commit('setTabsIndexChild', newVal);
        this.activeName = newVal + ''
        this.index = newVal
        this.queryList()
      },
    }
  },
  created() {
    if (this.$route.query.zgId) {
      this.zgId = this.$route.query.zgId
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      this.dictType = 'hj_estate_type'
    }
    if (this.$route.query.index) {
      this.$store.commit('setTabsIndexChild', this.$route.query.index);
      this.activeName = this.$route.query.index + ''
      this.index = this.$route.query.index
      this.queryList()
    }
    this.getCaseCenterTypes()
    // DictList(this.dictType).then(res => {
    //   this.activeList = res.data.data
    // })
  },
  methods: {
    queryList(){
      if (this.type) {
        this.index = this.zgId
        console.log('进入！！！！')
        // localStorage.setItem('centerTabsIndex', this.zgId)
        detailList(this.index,this.pageNum,this.pageSize).then(res => {
          this.dataList = res.data.rows || []
          this.total = Number(res.data.total)
        })
        return
      }
      if(this.index==1){
        this.index=undefined;
      }
      CaseCenterList(this.index,this.pageNum,this.pageSize).then(res => {
        this.dataList = res.data.rows || []
        this.total = Number(res.data.total)
      })
    },
    toPath(url,query){
      this.$router.push({
        path:url,
        query: { id: query,zgType:this.zgId }
      })
    },
    handleClick(tab) {
      this.pageNum = 1;
      this.pageSize = 12;
      this.currentPage1 = 1
      const item=JSON.parse(tab.name);
      this.index = item.id
      if(tab.index==0){
        this.showTabLabel = item.name
      }
      localStorage.setItem('centerTabsIndex', tab.index)
      this.queryList();
    },
    handleClick_one(tab) {
      console.log('tab', tab)

    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.queryList();
    },
    updateScreenDimensions() {
      this.currentScreenWidth = window.innerWidth;
    },
    getCaseCenterTypes(){
      caseCenterTypes().then(res => {
        this.activeList =this.handleTree(res.data.data,'id','pid')
        if(this.activeList!=undefined&&this.activeList.length>0){
          this.activeName = JSON.stringify(this.activeList[0])
          if(this.showTabLabel) return
          this.showTabLabel=this.activeList[0].name;
          this.index=this.activeList[0].id
          this.queryList()
        }
      }).catch(() => {});
    },
    handleCommand(command){
      this.showTabLabel=command.value.replace('-','/');
      this.index = command.id
      localStorage.setItem('centerTabsIndex', this.index)
      this.queryList();
    },
    handleGoSecure(){
      this.$bus.$on('goSecure',(item)=>{
        this.handleCommand(item)
        this.$store.commit('setTabsIndex', 2);
      })
    }
  },
  beforeDestroy(){
    this.$bus.$off('goSecure')
  },
  mounted() {
    
    this.handleGoSecure()
    
    this.updateScreenDimensions();
    window.addEventListener('resize', this.updateScreenDimensions);
  },
  beforeUnmount() {
    
    window.removeEventListener('resize', this.updateScreenDimensions);
  },
  computed: {
    screenWidth() {
      localStorage.setItem('screenWidth',this.currentScreenWidth)
      return this.currentScreenWidth;
    },
  },

}
</script>

<style scoped lang="scss">
@media (min-width: 991px){
  .abc{
    position: relative;
    top: -40px;
    padding-bottom: 40px;
  }
  .zhigaiBox{
    position: relative;
    top: -50px !important;
  }
  .titleTop{
    position: relative;
    top: -50px;
    text-align: center;
    font-weight: bold;
    height: 0;
    >span{
      font-size: 32px;
    }
  }
  .titleTop1{
    position: relative;
    top: -230px;
    text-align: center;
    font-weight: bold;
    height: 0;
    >span{
      font-size: 32px;
    }
  }
  .bjimg{
    width: 100%;
    height: 300px !important;
  }
  .box4{
    position: relative;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 75%;
    border-radius: 10px;
    .card-img-top{
      height: 134px;
      border-radius: 10px 10px 0px 0px;
    }
  }
  .card-title{
    font-size: 15px;
  }
  .image-container{
    height: 130px !important;
  }
}
@media (max-width: 991px) {
  .zhigaiBox{
    position: relative;
    top: -50px !important;
  }

  .titleTop{
    position: relative;
    top: -3.7rem;
    text-align: center;
    font-weight: bold;
    height: 0;
    >span{
      font-size: 22px;
    }
  }
  .titleTop1{
    position: relative;
    top: -92px !important;
    z-index: 99999999;
    text-align: center;
    font-weight: bold;
    height: 0;
    >span{
      font-size: 22px;
    }
  }
  .bjimg{
    width: 100%;
    height: 130px !important;
  }
  .box4{
    margin: auto;
    margin-top: 14px;
    max-width: 90%;
    border-radius: 10px;
    margin-bottom: 30px;
    .card4{
      max-width: 50%;
      height: 146px;
      margin-bottom: 10px;
    }
    .card4:nth-child(even){
      padding-left: 0;
    }
    .card-title{
      font-size: 14px;
    }

  }
}

::v-deep .tab-one .el-tabs__nav-wrap::after {
  height: 0 !important;
}

.card-body{
  text-align: center;
  font-size: 16px;
  padding-bottom: 0px;
}
.card{
  border: none !important;
  background: #f7f7f7;
  margin-bottom: 50px;
  cursor: pointer;
  .image-container{
    position: relative;
    display: inline-block; /* 使容器不占据整行 */
    overflow: hidden; /* 防止放大的图片超出容器 */
    border-radius: 10px;
    height: 100px;
    img{
      transition: transform 0.5s ease; /* 平滑的过渡效果 */
    }
  }
}

::v-deep .el-tabs__active-bar{
  background: #C7000B;
}
::v-deep .is-active {
  color: #C7000B;
}
::v-deep .el-tabs__item:hover {
  color: #C7000B;
}
::v-deep .active{
  background: #C7000B !important;
}
::v-deep .el-icon-arrow-left {
  color: red;
}
::v-deep .el-icon-arrow-right {
  color: red;
}
.card:hover {
  box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.1);
  img{
    transform: scale(1.3);
    z-index: 10;
  }
  .card-title{
    color: #C7000B;
  }
}
.card4{
  position: relative;
}
.text-truncate-1lines {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 限制显示行数为4 */
  -webkit-box-orient: vertical; /* 使文本垂直显示 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号代替隐藏的文本 */
}
</style>
