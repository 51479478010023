<template>
  <div style="background: #f8f8f8">
    <div class="container-fluid" style="padding: 0;" :style="{ paddingBottom: screenWidth < 992 ? '20px' : '80px' }">
      <!-- 大图 -->
      <div class="jumbotron mb-0 position-relative d-lg-block">
        <img :src="data.bg" class="img-fluid" alt="" style="max-height: 450px;min-height: 150px;width: 100%;">
<!--        <img src="../assets/imgs/aboutBg.png" class="img-fluid" alt="" style="max-height: 450px;min-height: 150px;">-->
      </div>
    </div>

    <div class="container">
      <!-- 主体内容区 -->
      <div class="row align-items-center justify-content-between contentBody topBox" :style="{ paddingBottom: screenWidth < 992 ? '30px' : '90px' }">
        <div class="col-lg-6 order-lg-1 order-2">
          <div class="text-truncate" style="margin-right: 16px">
            <h2 class="display-4 titleH2" style="color: #191919;font-size: 24px;font-weight: bold;padding-bottom: 20px">中心介绍</h2>
            <div class="lead" style="white-space: pre-wrap;color:#666666;font-size: 15px;line-height: 2;" :style="{ marginBottom: screenWidth < 992 ? '20px' : '46px' }" :class="{'text-truncate-4lines':screenWidth < 992 && !expanded}">
              <span style="text-indent: 28px;display: inline-block" v-if="data.introduce">{{data.introduce.title}}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 order-lg-2 order-1">
          <img v-if="data.introduce" :src="data.introduce.briefImage" alt="右侧图片" class="img-fluid liangceImg" style="margin-left:40px">
        </div>
      </div>
    </div>

    <div class="container" v-if="data.urbanActivity">
      <!-- 居中标题 -->
      <div class="py-2">
        <h1 class="display-4 cencetTitle" style="font-size: 28px;font-weight: bold;color:#191919;">区位优势</h1>
      </div>
      <div class="row align-items-center justify-content-between contentBody" v-for="(item,index) in data.urbanActivity" :key="index"
           style="flex-direction: row-reverse;" :style="[{ paddingBottom: screenWidth < 992 ? '30px' : '90px' },{ flexDirection: index % 2 === 0 ? 'row-reverse' : 'row' }]">
        <div class="col-lg-6 order-lg-2 order-1">
          <!-- 图片 -->
          <img :src="item.briefImage" alt="右侧图片" class="img-fluid liangceImg1">
        </div>
        <div class="col-lg-6 order-lg-1 order-2">
          <!-- 文字内容 -->
          <div class="text-truncate" >
            <h2 class="display-4 xpTitle" style="color: #191919;font-size: 22px;font-weight: 500;padding-bottom: 10px">{{item.titile}}</h2>
            <div class="lead" style="white-space: pre-wrap;color:#666666;font-size: 15px;line-height: 2;"
                 :style="[{ marginBottom: screenWidth < 992 ? '20px' : '46px' },{ marginBottom: screenWidth < 992 && expanded2 ? '60px' : '20px' }]"
                 :class="{'text-truncate-4lines':screenWidth < 992 && !expanded1}">
              <div>
                {{item.content}}
              </div>
            </div>
          </div>
        </div>
      </div>

<!--      <div class="row align-items-center justify-content-between contentBody" :style="{ paddingBottom: screenWidth < 992 ? '30px' : '90px' }">
        <div class="col-lg-6 order-lg-1 order-2">
          &lt;!&ndash; 文字内容 &ndash;&gt;
          <div class="text-truncate" style="margin-right: 16px">
            <h2 class="display-4 xpTitle" style="color: #191919;font-size: 22px;font-weight: 500;padding-bottom: 10px">区位优势</h2>
            <div class="lead" style="white-space: pre-wrap;color:#666666;font-size: 15px;line-height: 2;" :style="[{ marginBottom: screenWidth < 992 ? '20px' : '46px' },{ marginBottom: screenWidth < 992 && expanded1 ? '60px' : '20px' }]" :class="{'text-truncate-4lines':screenWidth < 992 && !expanded1}">
              <div>
                具备将数字机器人行业探索与场景应用推广至成渝城市群34个千亿级国家园区,对接成渝城市群“数字员工”虚拟人力资源需求，打造“人+数字员工”人力资源现代服务业。
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 order-lg-2 order-1">
          &lt;!&ndash; 图片 &ndash;&gt;
          <img src="@/assets/imgs/086.png" alt="右侧图片" class="img-fluid rounded liangceImg">
        </div>
      </div>
      <div class="row align-items-center justify-content-between contentBody" style="flex-direction: row-reverse;" :style="{ paddingBottom: screenWidth < 992 ? '30px' : '90px' }">
        <div class="col-lg-6 order-lg-2 order-1">
          <img src="@/assets/imgs/087.png" alt="右侧图片" class="img-fluid liangceImg1">
        </div>
        <div class="col-lg-6 order-lg-1 order-2">
          <div class="text-truncate mr-44" >
            <h2 class="display-4 xpTitle" style="color: #191919;font-size: 22px;font-weight: 500;padding-bottom: 10px">人才优势</h2>
            <div class="lead" style="white-space: pre-wrap;color:#666666;font-size: 15px;line-height: 2;" :style="[{ marginBottom: screenWidth < 992 ? '20px' : '46px' },{ marginBottom: screenWidth < 992 && expanded2 ? '60px' : '20px' }]" :class="{'text-truncate-4lines':screenWidth < 992 && !expanded1}">
              <div>
                四川是人才大省，高校院校众多，利用人才与成本优势，结合数字机器人产业，将成都打造为数字化人才基地，满足人才就业创业，辐射区域
              </div>
            </div>
          </div>
        </div>
      </div>-->

    </div>

    <div class="box4" style="background: white;" :style="{ paddingBottom: screenWidth < 992 ? '0px' : '100px' }" v-if="data.businessDomain">
      <div class="container" :style="{ paddingTop: screenWidth < 992 ? '20px' : '60px' }">
        <!-- 居中标题 -->
        <div class="py-3">
          <h1 class="display-4 cencetTitle" style="font-size: 28px;font-weight: 500;color:#191919;">业务领域</h1>
        </div>
        <div style="display: flex;" :style="{ margin: screenWidth < 992 ? '0' : '25px 0' }">
          <p style="color:#666666;font-size: 14px;line-height: 1.7" :class="{'twoLine':screenWidth < 992}">{{data.businessDomain.titile }}</p>
        </div>
        <div class="row">
          <!-- 大屏幕布局 -->
          <div class="col-lg-3 col-md-6 card4" v-for="(item,index) in data.businessDomain.list" :key="index" @click="toPath('/NewsConsultDetail',item.id)">
            <div class="card">
              <div class="image-container">
                <img :src="item.briefImage" class="card-img-top" alt="Image">
              </div>
              <div class="card-body">
                <h5 class="card-title">{{ item.briefI }}</h5>
                <p class="card-text text-truncate-3lines" :title="item.content" v-html="item.content">
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {getAboutUs} from "@/api/common";

export default {
  data() {
    return {
      expanded: false,
      expanded1: false,
      expanded2: false,
      currentScreenWidth: null,
      data:{},
    }
  },
  created() {
    getAboutUs().then(res => {
      this.data = res.data.data
    })
    this.$store.commit('setTabsIndex', 4);
  },
  methods: {
    toPath(url,id){
      this.$router.push({
        path:url,
        query: { id: id , zgType:200 }
      })
      this.$store.commit('setTabsIndex', 4);
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    toggleExpanded1() {
      this.expanded1 = !this.expanded1;
    },
    toggleExpanded2() {
      this.expanded2 = !this.expanded2;
    },
    updateScreenDimensions() {
      this.currentScreenWidth = window.innerWidth;
    },
  },
  mounted() {
    this.updateScreenDimensions();
    window.addEventListener('resize', this.updateScreenDimensions);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenDimensions);
  },
  computed: {
    screenWidth() {
      return this.currentScreenWidth;
    },
  },
}
</script>

<style scoped lang="scss">
@media (min-width: 991px) {
  .box4{
    .col-lg-3{
      .card-body{
        padding: 16px 14px !important;
      }
    }
  }
  .col-lg-35{
    width: 31.46%;
    flex: 0 0 auto;
    .card-body{
      text-align: left !important;
    }
  }
}
@media (max-width: 991px) {
  .liangceImg{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .mr-44{
    margin-left: 0px !important;
  }
  .contentBody{
    padding-bottom: 0 !important;
    margin-bottom: 30px;
    flex-direction: column-reverse !important;
    >.order-2{
      margin: 0 !important;
      background: white;
      width: 95%;
      border-radius: 0 0 6px 6px;
    }
  }
  .topBox{
    text-align: justify; /* 文本左右对齐 */
    text-justify: inter-word; /* 单词之间对齐 */
    >.order-2{
      background: none !important;
      width: 100%;
    }
  }
  .titleH1 {
    font-size: 24px;
  }
  .titleH2{
    margin-top: 20px;
    color: #262a32;
    font-size: 18px !important;
    text-align: center;
    padding-bottom: 6px !important;
  }
  .titleH2:before{
    content: '';
    position: absolute;
    top: 30px !important;
    left: 50% !important;
    height: 3px !important;
    width: 24px !important;
    background: #C7000B;
    border-radius: 3px 3px 3px 3px;
  }
  .lianxi{
    padding: 5px 20px !important;
    font-size: 13px !important;
    letter-spacing: 1.3px;
  }
  .row.align-items-center.justify-content-between {
    flex-direction: column-reverse;
  }
  .cencetTitle{
    font-size: 20px !important;
    font-weight: 500;
    text-align: center;
    padding-left: 0 !important;
  }
  .cencetTitle::before{
    content: '';
    position: absolute;
    bottom: -17px !important;
    left: 50% !important;
    transform: translateX(-50%);
    height: 4px !important;
    width: 30px !important;
    background: #C7000B;
    border-radius: 3px 3px 3px 3px;
  }
  .xpTitle{
    padding-top: 20px;
    font-weight: 500 !important;
    font-size: 19px !important;
  }
  .py-5{
    padding-top: 10px !important;
    padding-bottom: 2rem !important;
  }
  .card{
    border: none !important;
  }
  .card-body{
    text-align: left !important;
  }
  .card-title{
    color: #252B39 !important;
    font-size: 15.5px !important;
    font-weight: bold !important;
  }
  .box4{
    .card-title{
      text-align: center;
    }
    p{
      text-align: center;
    }
  }
}
//上面是小屏幕
.mr-44{
  margin-left: 44px;
}
.cencetTitle{
  position: relative;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 500;
  color:#191919;
  padding-left: 30px;
}
.cencetTitle::before{
  content: '';
  position: absolute;
  left: 4px;
  bottom: 0%;
  width: 4px;
  height: 36px;
  background: #C7000B;
  border-radius: 3px 3px 3px 3px;
}

.text-truncate-4lines {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* 限制显示行数为4 */
  -webkit-box-orient: vertical; /* 使文本垂直显示 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号代替隐藏的文本 */
}
.lianxi{
  border-radius: 3px !important;
}
.card {
  margin-bottom: 20px;
  height: 320px;
  flex: 1;
  border-color: #E6E6E6;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  .image-container{
    position: relative;
    display: inline-block; /* 使容器不占据整行 */
    overflow: hidden; /* 防止放大的图片超出容器 */
    border-radius: 10px 10px 0 0;
    img{
      transition: transform 0.5s ease; /* 平滑的过渡效果 */
    }
  }
}
.card:hover {
  box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.1);
  img{
    transform: scale(1.3);
    z-index: 10;
  }
  .card-title{
    color: #C7000B;
  }
}
.card-deck {
  display: flex;
  flex-wrap: wrap;
}
.card-img-top{
  height: 170px;
}
.card-img-top3{
  height: 200px;
}
.card-body{
  text-align: center;
  .card-title{
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .card-text{
    line-height: 22px;
    font-size: 14px;
    color: #666666;
    text-transform: none;
  }
}
.logoBotton{
  width: 300px;
  height: 121px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  line-height: 121px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 125px;
  }
}
.titleH2{
  position: relative;
  padding-left: 20px;
}
.titleH2:before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 30px;
  background: #C7000B;
  border-radius: 3px 3px 3px 3px;
}

.text-truncate-3lines {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 限制显示行数为4 */
  -webkit-box-orient: vertical; /* 使文本垂直显示 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 使用省略号代替隐藏的文本 */
}
</style>
