<template>
  <div style="background: #f6f6f6;height: 1000px;display: flex;justify-content: center;padding-top: 150px;">
    <img style="width: 180px;height: 180px;" src="@/assets/imgs/nuli.png" alt="">
  </div>
</template>
<script>
  export default {
    data(){
      return {

      }
    },

  }

</script>
<style scoped lang="scss">

</style>
